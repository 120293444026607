import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import StandClient from "client";

const Footer = (props) => {
    const [conf, setConf] = useState(null);
    const [grid1, setGrid1] = useState([]);
    const [grid2, setGrid2] = useState([]);
    const phoneNumber = StandClient.getConfigValueByName('phone_number');
    const address = StandClient.getConfigValueByName('address');
    const email = StandClient.getConfigValueByName('email');
    const siteFooterLabel = StandClient.getConfigValueByName('site_footer_label');
    const firstMenuFooterLabel = StandClient.getConfigValueByName('first_menu_footer_label');
    const secondMenuFooterLabel = StandClient.getConfigValueByName('second_menu_footer_label');
    const telegramLink = StandClient.getConfigValueByName('site_footer_telegram_link');
    const paymentSystemsImg = StandClient.getConfigValueByName('payment_systems_img');
    const organizationName = StandClient.getConfigValueByName('organization_name');
    const inn = StandClient.getConfigValueByName('inn');

    useEffect(() => {
        const conf = StandClient.getFrontConfigureLocal();
        setConf(conf)
        let i = 0;
        conf.data.pages.forEach((el) => {
            if (el.footerMenu) {
                const buf = {title: el.name, link: "/page/"+el.id+"/"};
                if (i % 2 === 0) {
                    setGrid1(prevArray => [...prevArray, buf])
                } else {
                    setGrid2(prevArray => [...prevArray, buf])
                }
                i = i++;
            }
        });
    }, []);

    return (
        <React.Fragment>
            <footer className={props.isLight ? "footer bg-light" : "footer"}>
                <Container>
                    <Row>
                        <Col lg="4" xs="12" className="mb-0 mb-md-4 pb-0 pb-md-2" name="footercolumn">
                            <Link to="#" className="logo-footer">
                                {conf && conf.data.getFrontConfigure.title}
                            </Link>
                            <p className={props.isLight ? "mt-4 text-muted" : "mt-4"}>
                                {siteFooterLabel}
                            </p>
                            <p className={props.isLight ? "mt-4 text-muted" : "mt-4"}>
                                {organizationName ? (<span>{organizationName}<br/></span>) : (null)}
                                {inn ? (<span>{`ИНН: ${inn}`}<br/></span>) : (null)}
                                {email ? (<span>{email}<br/></span>) : (null)}
                            </p>
                            <ul className={props.isLight ? "list-unstyled social-icon social mb-0 m t-4" : "list-unstyled social-icon foot-social-icon mb-0 mt-4"}>
                                {conf && conf.data.getFrontConfigure.socialNetworkFacebook ? 
                                    <li className="list-inline-item me-1">
                                        <a href={conf && conf.data.getFrontConfigure.socialNetworkFacebook} target="_blank" className="rounded" rel="noreferrer">
                                            <FeatherIcon icon="facebook" className="fea icon-sm fea-social" />
                                        </a>
                                    </li>
                                : ""}
                            {conf && conf.data.getFrontConfigure.socialNetworkInstagram ? 
                                <li className="list-inline-item me-1">
                                    <a href={conf && conf.data.getFrontConfigure.socialNetworkInstagram} target="_blank" className="rounded" rel="noreferrer">
                                        <FeatherIcon icon="instagram" className="fea icon-sm fea-social" />
                                    </a>
                                </li>  
                            : ""}
                            {conf && conf.data.getFrontConfigure.socialNetworkVKontakte ? 
                                <li className="list-inline-item me-1">
                                    <a href={conf && conf.data.getFrontConfigure.socialNetworkVKontakte} target="_blank" className="rounded" rel="noreferrer">
                                        vk
                                    </a>
                                </li>  
                            : ""}
                            {telegramLink ? 
                                <li className="list-inline-item me-1">
                                    <a href={telegramLink} target="_blank" className="rounded" rel="noreferrer">
                                        <FeatherIcon icon="send" className="fea icon-sm fea-social"/>
                                    </a>
                                </li>  
                            : ""}
                            </ul>
                        </Col>

                        <Col lg="2" md="4" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0" name="footercolumn">
                            <h5 className={props.isLight ? "text-dark footer-head" : "text-light footer-head"}>
                                {firstMenuFooterLabel}
                            </h5>
                            <ul className="list-unstyled footer-list mt-4">
                                {grid1.map((grid, key) => (
                                    <li key={key}>
                                        <a href={grid.link} className={props.isLight ? "text-muted" : "text-foot"}>
                                            <i className="mdi mdi-chevron-right me-1"></i>
                                            {grid.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </Col>

                        <Col lg="3" md="4" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0" name="footercolumn">
                            <h5 className={props.isLight? "text-dark footer-head" : "text-light footer-head"}>
                                {secondMenuFooterLabel}
                            </h5>
                            <ul className="list-unstyled footer-list mt-4">
                                {grid2.map((grid, key) => (
                                    <li key={key}>
                                        <a href={grid.link} className={props.isLight ? "text-muted" : "text-foot"}>
                                            <i className="mdi mdi-chevron-right me-1"></i>
                                            {grid.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <footer className="footer footer-bar">
                <Container className="text-center">
                    <Row className="align-items-center">
                        <Col sm="6">
                            <div className="text-sm-start">
                                <p className="mb-0">
                                    { phoneNumber ? (<a href={("tel:"+phoneNumber)} className="text-reset">
                                        <i className="mdi mdi-phone text-info"></i> {phoneNumber}
                                    </a>) : null} {" "}
                                    { address ? (
                                        <span><i className="mdi mdi-home-map-marker text-info"></i> {address}</span>
                                    ) : null}
                                </p>
                            </div>
                        </Col>
                        {(paymentSystemsImg === '' || paymentSystemsImg === 'null') ? (null) : 
                            (<Col sm="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <ul className="list-unstyled text-sm-end mb-0">
                                    <li className="list-inline-item me-1">
                                        <img src={paymentSystemsImg} className="avatar avatar-ex-sm" title="Paykeeper" alt="" />
                                    </li>
                                </ul>
                            </Col>)}
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
}

export default Footer;
