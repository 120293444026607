import { Container, Row, Col } from "reactstrap";

const BlockInfoPointsImgLeft = (props) => {
    const cont = JSON.parse(props.block.content);
    const points = (cont.points) ? (cont.points) : ([]);

    return (
        <section className="section overflow-hidden">
            <Container>
                <Row className="align-items-center">
                    <Col lg={6} md={{ size: 6, order: 2 }} className="order-1">
                        <img src={cont.image} className="img-fluid" alt="" />
                    </Col>
                    <Col lg={6} md={6} className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="section-title ms-lg-5">
                            <h4 className="title mb-4">{cont.title}</h4>
                            <p className="text-muted">{cont.body}</p>
                            <ul className="list-unstyled text-muted">
                                {points.map((elem,k)=>{
                                    return (
                                        <li className="mb-0" key={k}>
                                            <span className="text-primary h5 me-2">
                                                <i className="uil uil-check-circle align-middle" />
                                            </span>
                                            {elem}
                                        </li>
                                    );
                                })}
                            </ul>
                            <a href={cont.buttonUrl} className="mt-3 h6 text-primary">{cont.buttonLabel} <i className="uil uil-angle-right-b" /></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default BlockInfoPointsImgLeft;
