import React, { useEffect, useState } from "react";
import { 
    Container,
    Row,
    Col,
    FormGroup,
    Card,
    CardBody,
    CardHeader,
    Collapse,
    Label,
    Input,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import ReactSearchAutocomplete from "components/Search/ReactSearchAutocomplete";
import Products from "components/ui/Products";
import StandClient from "client";
import {Slider} from '@gravity-ui/uikit';

const ProductsPage = (props) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        products: null,
        totalProductCount: 0,
        topProducts: [],
        currentPage: 1,
        perPage: (parseInt( StandClient.getConfigValueByName('products_per_page') ) || 21),
        lastPage: false,
        sortStr: 'populardesc',
        sortField: 'total_ordered_count',
        sortOrder: 'DESC',
        searchItems: [],
        col1: true,
        col2: false,
    })

    const listFilters = [
        {"name": "Категории"},
        {"name": "Цена"},
        {"name": "Производитель"},
        {"name": "Игровой ноутбук (да/нет)"},
        {"name": "Линейка процессора"},
        {"name": "Видеокарта"},
        {"name": "Объем оперативной памяти"},
        {"name": "Накопитель"},
        {"name": "Диагональ"},
        {"name": "Разрешение экрана"}
    ]

    const t_col1 = (e) => {
        setState(prev => {
            return {
                ...prev,
                col1: true,
                col2: false
            }
        })
    };
    const t_col2 = (e) => {
        setState(prev => {
            return {
                ...prev,
                col2: true,
                col1: false
            }
        })
    };
    const openFilter = (e) => {
        setState(prev => {
            return {
                ...prev,
                col1: true,
                col2: false
            }
        })
    };

    useEffect(() => {
        StandClient.getProducts(null, state.currentPage, state.perPage, state.search, state.sortField, state.sortOrder).then(result=>{
            setState(prev => {
                return {
                    ...prev,
                    products: result.landrickProducts,
                    totalProductCount: result.landrickProductCount.count,
                }
            })
        }).catch(error=>{
            console.log("ERROR!!!");
            console.log(error);
            setState(prev => {
                return {
                    ...prev,
                    products: []
                }
            })
        });
        StandClient.getDiscountProducts(5).then(result=>{
            setState(prev => {
                return {
                    ...prev,
                    topProducts: result.landrickDiscountProducts
                }
            })
        }).catch(error=>{
            console.log("ERROR!!!");
            console.log(error);
            setState(prev => {
                return {
                    ...prev,
                    topProducts: []
                }
            })
        });
    }, []);

    const changeSort = (newSortStr) => {
        setState(prev => {
            return {
                ...prev,
                products: [],
                currentPage: 1,
                lastPage: false,
                sortStr: newSortStr,
                sortField: StandClient.sortMap[newSortStr].sortField,
                sortOrder: StandClient.sortMap[newSortStr].sortOrder,
            }
        })
        StandClient.getProducts(null, 1, state.perPage, state.search, StandClient.sortMap[newSortStr].sortField, StandClient.sortMap[newSortStr].sortOrder).then(result=>{
            setState(prev => {
                return {
                    ...prev,
                    products: result.landrickProducts,
                    totalProductCount: result.landrickProductCount.count,
                }
            })
        }).catch(error=>{
            console.log("ERROR!!!");
            console.log(error);
            setState(prev => {
                return {
                    ...prev,
                    products: []
                }
            })
        });
    }

    const loadMore = () => {
        StandClient.getProducts(null, state.currentPage+1, state.perPage, state.search, state.sortField, state.sortOrder).then(result=>{
            let ps = state.products;
            result.landrickProducts.forEach((val)=>{
                ps.push(val);
            });
            setState(prev => {
                return {
                    ...prev,
                    products: ps,
                    totalProductCount: result.landrickProductCount.count,
                    currentPage: prev.currentPage+1
                }
            })
        }).catch(error=>{
            console.log("ERROR!!!");
            console.log(error);
            setState(prev => {
                return {
                    ...prev,
                    lastPage: true
                }
            })
        });
    }

    const formatResult = (item) => {
        if (item.type === 'suggest') {
            return (
                <>
                <span style={{ display: 'block', textAlign: 'left' }}><i>Вы имели в виду:</i></span>
                <span style={{ display: 'block', textAlign: 'left' }}><b>{item.name}</b></span>
                </>
            )
        }
        if (item.type === 'autocomplete') {
            return <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        }
        return (
            <table>
                <tr>
                    <td style={{width:48}}><img src={item.image} width={48} /></td>
                    <td>
                        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
                        <span style={{ display: 'block', textAlign: 'left' }}>{item.price}</span>
                    </td>
                </tr>
            </table>
        )
    }

    const conf = StandClient.getFrontConfigureLocal();
    document.title = 'Главная - ' + StandClient.getConfigValueByName('site_title');
    const hasSearch = StandClient.getConfigValueByName('_site_search_id');

    return (
        <React.Fragment>
            <section className="section">
                <Container>
                    <Row>
                        <Col lg={3} md={4} xs={12}>
                            <Card className="border-0 sidebar sticky-bar">
                                <CardBody className="p-0">
                                    {hasSearch === '' ? (null) : (
                                        <div className="widget">
                                            <div id="search2" className="widget-search mb-0">
                                                <ReactSearchAutocomplete
                                                    onSelect={(item) => navigate(item.url)}
                                                    showNoResultsText="Нет результатов"
                                                    formatResult={formatResult}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="widget mt-4 pt-2">
                                    
                                    
                                    
                                    
                                    
                                        <h5 className="widget-title">Категории</h5>
                                        <ul className="list-unstyled mt-4 mb-0 blog-categories">
                                            {conf.data.rootCategorys.map((cat,i)=> {
                                                return (<li key={i}><a href={"/category/"+cat.id+"/"}>{cat.name}</a></li>);
                                            })}
                                        </ul>
    

                                        {/* <h5 className="widget-title">Категории</h5> */}

                                        {/* <div className="accordion" id="accordionExample">

                                            {listFilters.map((filter, index) => {
                                                return (
                                                    <Card className="border-0 rounded mb-2">
                                                        <Link to="#" onClick={(e) => {openFilter(index)}} className={"faq position-relative text-dark"}>
                                                            <CardHeader className="border-0" id="headingOne" style={{padding: 0, backgroundColor: "transparent"}}>
                                                                <h6 className="title mb-0">
                                                                    {filter.name} */}
                                                                    {/* TODO: Если фильтр один - "mdi-chevron-up" и "mdi-chevron-down" необходимо убрать */}
                                                                    {/* <i className={
                                                                        state.col1
                                                                        ? "mdi mdi-chevron-up float-end"
                                                                        : "mdi mdi-chevron-down float-end"
                                                                    }></i>
                                                                </h6>
                                                            </CardHeader>
                                                        </Link>
                                                        <Collapse isOpen={state.col1}>
                                                            <CardBody style={{padding: 10}}>
                                                                <div className="form-check">
                                                                    <Input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customCheck1"
                                                                    />
                                                                    <Label
                                                                        className="form-check-label"
                                                                        htmlFor="customCheck1"
                                                                        >
                                                                            Запомнить
                                                                    </Label>
                                                                </div>


                                                                <Slider min={20} max={60} defaultValue={[20, 40]} hasTooltip={true} />
                                                                <Input
                                                                    type="number" 
                                                                    placeholder="Цена от"
                                                                    style={{width: "50%", float: "left"}}
                                                                />
                                                                <Input
                                                                    type="number" 
                                                                    placeholder="Цена до"
                                                                    style={{width: "50%", float: "right"}}
                                                                />
                                                            </CardBody>
                                                        </Collapse>
                                                    </Card>
                                                )
                                            })}

                                            <Card className="border-0 rounded mb-2">
                                                <Link
                                                    to="#"
                                                    onClick={(e) => {t_col1(e)}}
                                                    className={
                                                        state.col1
                                                        ? "faq position-relative text-dark"
                                                        : "faq position-relative text-dark"
                                                    }
                                                >
                                                    <CardHeader className="border-0" id="headingOne" style={{padding: 0, backgroundColor: "transparent"}}>
                                                        <h6 className="title mb-0">
                                                            How our Landrick work?
                                                            <i className={
                                                                state.col1
                                                                ? "mdi mdi-chevron-up float-end"
                                                                : "mdi mdi-chevron-down float-end"
                                                            }></i>
                                                        </h6>
                                                    </CardHeader>
                                                </Link>
                                                <Collapse isOpen={state.col1}>
                                                    <CardBody style={{padding: 10}}>
                                                        <div className="form-check">
                                                            <Input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="customCheck1"
                                                            />
                                                            <Label
                                                                className="form-check-label"
                                                                htmlFor="customCheck1"
                                                                >
                                                                    Запомнить
                                                            </Label>
                                                            <Input
                                                                type="number" 
                                                                placeholder="Цена от"
                                                            />
                                                            <Input
                                                                type="number" 
                                                                placeholder="Цена до"
                                                            />
                                                        </div>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>

                                            <Card className="border-0 rounded mb-2">
                                                <Link
                                                    to="#"
                                                    onClick={(e) => {t_col2(e)}}
                                                    className={
                                                        state.col2
                                                        ? "faq position-relative text-primary"
                                                        : "faq position-relative text-dark"
                                                    }
                                                >
                                                    <CardHeader className="border-0" id="headingTwo" style={{padding: 0, backgroundColor: "transparent"}}>
                                                        <h6 className="title mb-0">
                                                            Категории
                                                            <i className={
                                                                state.col2
                                                                ? "float-end"
                                                                : "float-end"
                                                            }></i>
                                                        </h6>
                                                    </CardHeader>
                                                </Link>
                                                <Collapse isOpen={state.col2}>
                                                    <CardBody style={{padding: 10}}>
                                                        <ul className="list-unstyled mb-0 blog-categories">
                                                            {conf.data.rootCategorys.map((cat,i)=> {
                                                                return (<li key={i}><a href={"/category/"+cat.id+"/"}>{cat.name}</a></li>);
                                                            })}
                                                        </ul>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                        </div> */}

                                        
                                    </div>
                                    {state.topProducts.length === 0 ? (null) : (
                                        <div className="widget mt-4 pt-2">
                                            <h5 className="widget-title">Акции</h5>
                                            <ul className="list-unstyled mt-4 mb-0">
                                                {state.topProducts.map((product, key) => (
                                                    <li
                                                    key={key}
                                                    className={
                                                        key + 1 === 1
                                                        ? "d-flex align-items-center"
                                                        : "d-flex align-items-center mt-2"
                                                    }
                                                    >
                                                        <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"}>
                                                            <img
                                                                src={product.image}
                                                                className="img-fluid avatar avatar-small rounded shadow"
                                                                style={{ height: "auto" }}
                                                                alt="Landrick"
                                                            />
                                                        </a>
                                                        <div className="flex-1 content ms-3">
                                                            <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="text-dark h6">
                                                                {product.name}
                                                            </a>
                                                            <h6 className="text-muted small fst-italic mb-0 mt-1">
                                                            {product.price}₽{" "}
                                                            <del className="text-danger ms-2">
                                                                {product.oldPrice}₽
                                                            </del>{" "}
                                                            </h6>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={9} md={8} xs={12} className="mt-5 pt-2 mt-sm-0 pt-sm-0">
                            <Row className="align-items-center">
                                <Col lg={8} md={7}>
                                    <div className="section-title">
                                        <h5 className="mb-0">Все товары</h5>
                                    </div>
                                </Col>
                                <Col lg={4} md={5} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div className="d-flex justify-content-md-between align-items-center">
                                        <div className="form custom-form">
                                            <FormGroup >
                                                <select className="form-select form-control" onChange={(e)=>{changeSort(e.target.value);}} >
                                                    <option value="populardesc">Сначала популярные</option>
                                                    <option value="priceasc">Сначала бюджетные</option>
                                                    <option value="pricedesc">Сначала дорогие</option>
                                                    <option value="iddesc">Сначала новые</option>
                                                </select>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Products loadMore={loadMore} state={state} />
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default ProductsPage;
