import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import StandClient from "client";
import Loader from "components/ui/Loader";
import Error404 from "components/pageBlockConstructor/pages/Error404";
import HeaderConstructor from "components/ui/headerConstructor";
import ProductsPage from "components/pageBlockConstructor/pages/ProductsPage";
import BlockHtml from "components/pageBlockConstructor/blocks/BlockHtml";
import BlockIframe from "components/pageBlockConstructor/blocks/BlockIframe";
import BlockProducts from "components/pageBlockConstructor/blocks/BlockProducts";
import BlockSimpleParalax from "components/pageBlockConstructor/blocks/BlockSimpleParalax";
import BlockInfoPointsImgLeft from "components/pageBlockConstructor/blocks/BlockInfoPointsImgLeft";
import BlockSimpleForm from "components/pageBlockConstructor/blocks/BlockSimpleForm"
import ProductsListPage from "./pages/ProductsListPage";
import ProductPageContentBlock from "./pages/ProductPageContentBlock";
import StaticPageContentBlock from "./pages/StaticPageContentBlock";
import SelectCity from "./pages/SelectCity";



// import BlockInfoPointsImgRight from "../../../constructor/blocks/BlockInfoPointsImgRight";
// import BlockFaqImgLeft from "../../../constructor/blocks/BlockFaqImgLeft";
// import BlockFaqImgRight from "../../../constructor/blocks/BlockFaqImgRight";
// import BlockCollections from "../../../constructor/blocks/BlockCollections";
// import BlockOrderSearch from "../../../constructor/blocks/BlockOrderSearch";
// import BlockGallery from "../../../constructor/blocks/BlockGallery";
// import BlockSimpleForm from "../../../constructor/blocks/BlockSimpleForm";
// import PageLogin from "../../../constructor/PageContentBlocks/AuthPages/PageLogin";
// import PageSignUp from "../../../constructor/PageContentBlocks/AuthPages/PageSignup";
// import PageRecoveryPassword from "../../../constructor/PageContentBlocks/AuthPages/PageRecoveryPassword";
// import PageProfile from "../../../constructor/PageContentBlocks/Account/PageProfile";
// import PageProfileEdit from "../../../constructor/PageContentBlocks/Account/PageProfileEdit";
// import PageMessages from "../../../constructor/PageContentBlocks/Account/PageMessages";
// import BlockQuiz from "../../../constructor/blocks/BlockQuiz";

const RenderBlockContent = (props) => {
    // console.log("RenderBlockContent init")
    // console.log(props.page.pageType)
    if (props.page === undefined || props.page === null) {
        return null;
    }
    if (props.page.pageType === 'STATIC_PAGE') {
        return <StaticPageContentBlock page={props.page} />;
    }
    if (props.page.pageType === 'PRODUCTS_PAGE') {
        return <ProductsPage page={props.page} />;
    }
    if (props.page.pageType === 'PRODUCT_DETAIL') {
        return <ProductPageContentBlock page={props.page} />;
    }
    if (props.page.pageType === 'PRODUCTS_LIST_PAGE') {
        return <ProductsListPage page={props.page} />;
    }
    // if (props.page.pageType === 'AUTH_LOGIN') {
    //     return <PageLogin page={props.page} params={props.params} />;
    // }
    // if (props.page.pageType === 'AUTH_SIGNUP') {
    //     return <PageSignUp page={props.page} params={props.params} />;
    // }
    // if (props.page.pageType === 'AUTH_RECOVERY') {
    //     return <PageRecoveryPassword page={props.page} params={props.params} />;
    // }
    // if (props.page.pageType === 'AUTH_PROFILE') {
    //     return <PageProfile page={props.page} params={props.params} />;
    // }
    // if (props.page.pageType === 'AUTH_PROFILE_EDIT') {
    //     return <PageProfileEdit page={props.page} params={props.params} />;
    // }
    // if (props.page.pageType === 'AUTH_PROFILE_MESSAGE') {
    //     return <PageMessages page={props.page} params={props.params} />;
    // }
    return null;
}

const RenderBlock = (props) => {
    // console.log("RenderBlock")
    // console.log(props.block)
    if (props.block === undefined || props.block === null) {
        return null;
    }
    const blockType = props.block.blockType;
    if (blockType === 'HTML_CONTENT') {
        return <BlockHtml block={props.block} page={props.page} />
    }
    if (blockType === 'IFRAME') {
        return <BlockIframe block={props.block} page={props.page} />
    }
    if (blockType === 'PRODUCT_LIST') {
        return <BlockProducts block={props.block} page={props.page} />
    }
    if (blockType === 'SIMPLE_PARALAX') {
        return <BlockSimpleParalax block={props.block} page={props.page} />
    }
    if (blockType === 'INFO_POINTS_IMG_LEFT') {
        return <BlockInfoPointsImgLeft block={props.block} page={props.page} />
    }
    if (blockType === 'SELECT_CITY') {
        return <SelectCity {...props.block} />;
    }
    if (blockType === 'SIMPLE_FORM') {
        return <BlockSimpleForm {...props} />
    }
    // if (blockType === 'INFO_POINTS_IMG_RIGHT') {
    //     return <BlockInfoPointsImgRight block={props.block} page={props.page} />
    // }
    // if (blockType === 'INFO_FAQ_IMG_LEFT') {
    //     return <BlockFaqImgLeft block={props.block} page={props.page} />
    // }
    // if (blockType === 'INFO_FAQ_IMG_RIGHT') {
    //     return <BlockFaqImgRight block={props.block} page={props.page} />
    // }
    // if (blockType === 'COLLECTIONS') {
    //     return <BlockCollections block={props.block} page={props.page} />
    // }
    // if (blockType === 'GALLERY2') {
    //     return <BlockGallery block={props.block} page={props.page} />
    // }
    // if (blockType === 'QUIZ') {
    //     return <BlockQuiz block={props.block} page={props.page} />
    // }
    // if (blockType === 'ORDER_SEARCH') {
    //     return <BlockOrderSearch page={props.page} params={props.params} block={props.block} />
    // }
    if (blockType === 'PAGE_CONTENT_BLOCK') {
        return <RenderBlockContent page={props.page} params={props.params} block={props.block} />
    }
    return null;
}

const checkMediaQuery = (isMobile, isPortrait, mediaQueryDevice, mediaQueryOrientation) => {
    if (mediaQueryDevice === 'ALL' && mediaQueryOrientation === 'ALL') {
        return true;
    }
    if (!isMobile) {
        return (mediaQueryDevice === 'ALL' || mediaQueryDevice === 'DESKTOP');
    }
    if (mediaQueryDevice === 'ALL' || mediaQueryDevice === 'MOBILE') {
        if (isPortrait) {
            return (mediaQueryOrientation === 'ALL' || mediaQueryOrientation === 'PORTRAIT');
        } else {
            return (mediaQueryOrientation === 'ALL' || mediaQueryOrientation === 'LANDSCAPE');
        }
    }
    return false;
}

const PageBlockConstructor = (props) => {
    let location = useLocation();
    const isMobile = useMediaQuery({ maxWidth: 1224 });
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(null);

    useEffect(() => {
        StandClient.getPageByPath(location.pathname).then((result)=>{
            setLoading(false);
            setPage(result);
            document.title = result.name + ' - ' + StandClient.getConfigValueByName('site_title');
        }).catch((error)=>{
            console.log(error);
        });
    }, [location]);

    if (loading) {
        return <Loader />
    }
    if (page === null) {
        return <Error404 />
    }

    const conf = StandClient.getFrontConfigureLocal();
    return (
        <React.Fragment>
            <HeaderConstructor
                title={page.title}
                pathItems={[
                    { id: 1, name: "Главная", link: "/" },
                    { id: 2, name: page.name, link: page.path },
                ]}
                conf={conf}
                pageType="page_content"
                page={page}
                jsonSettings={page.headerViewJsonSettings}
                viewType={page.headerViewTypeName} />

            {page.blocks.map((item, key) => {
                return (checkMediaQuery(isMobile, isPortrait, item.block.mediaQueryDevice, item.block.mediaQueryOrientation))?
                    (<RenderBlock key={item.block.id} block={item.block} page={page} params={props.params} />):(null);
            })}
        </React.Fragment>
    );
}

export default PageBlockConstructor;
