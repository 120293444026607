
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import gql from 'graphql-tag';
import ym from 'react-yandex-metrika';

const gclient = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
        uri: '/gql/'
    }),
    connectToDevTools: true,
});

const StandClient = {
    getFrontConfigure: () => new Promise((resolve, reject) => {
        let query = gql`query{getFrontConfigure{title, engine, headerBack, logoIcon, socialNetworkInstagram, socialNetworkFacebook, socialNetworkVKontakte, configs {name, value}, navLinks {title,link,sub {title,link} }, autoAddingProducts { id,name,image,price,oldPrice,slug }, onlinePaymentEnabled }, rootCategorys { id,name,categorySet {id,name}, slug }, pages {id, name, publish, headerMenu, footerMenu}, landrickSalePoints { id,name,description,address,latitude,longitude,inn,phone }, allCategorys { id, name, parent {id} } }`;
        gclient.query({ query: query, fetchPolicy: "no-cache" }).then(result => {
            localStorage.setItem('frontConfigure', JSON.stringify(result));
            resolve({ result });
        }).catch(error => {
            reject({ error })
        });
    }),
    getFrontConfigureLocal: () => {
        return JSON.parse(localStorage.getItem('frontConfigure'));
    },
    getConfigValueByName: (name, def = '') => {
        const getFrontConfigureLocalVar = JSON.parse(localStorage.getItem('frontConfigure'));
        var val = def;
        getFrontConfigureLocalVar.data.getFrontConfigure.configs.forEach((el) => {
            if (el.name === name) {
                val = el.value;
            }
        });
        return val;
    },
    getProducts: (category = null, page = null, perPage = null, search = "", sortField = null, sortOrder = null) => new Promise((resolve, reject) => {
        let query = gql('query { landrickProducts(' +
            'search:"' + (search == null ? '' : search) + '"' +
            (category == null ? '' : ',categoryId:' + category) +
            (page == null ? '' : ',page:' + page) +
            (perPage == null ? '' : ',perPage:' + perPage) +
            (sortField == null ? '' : ',sortField:"' + sortField + '"') +
            (sortOrder == null ? '' : ',sortOrder:"' + sortOrder + '"') +
            ') { id,name,image,imgOverlay,price,oldPrice,isNew,isFeatures,isSale,outofstock,slug }, ' +
            'landrickProductCount(' +
            'search:""' +
            (category == null ? '' : ',categoryId:' + category) +
            ') { count }' +
            (category == null ? '' : ', category(id:' + category + '){ id, name, }') +
            '} ');
        gclient.query({ query: query }).then(result => {
            if (search !== "") {
                StandClient.yandexMetrika.hit('?search=' + search, { params: { page: page } });
            } else {
                if (category === null) {
                    StandClient.yandexMetrika.hit('/', { params: { page: page } });
                } else {
                    StandClient.yandexMetrika.hit('/category/' + category + '/', { params: { page: page, category: category } });
                }
            }
            resolve(result.data);
        }).catch(error => {
            reject(error);
        });
    }),
    getDiscountProducts: (perPage = 5) => new Promise((resolve, reject) => {
        let query = gql('query { landrickDiscountProducts(' +
            (perPage == null ? '' : 'perPage:' + perPage) +
            ') { id,name,image,imgOverlay,price,oldPrice,isNew,isFeatures,isSale,outofstock,slug } } ');
        gclient.query({ query: query }).then(result => {
            resolve(result.data);
        }).catch(error => {
            reject(error);
        });
    }),
    getProduct: (id) => new Promise((resolve, reject) => {
        let query = gql('query { landrickProduct(' +
            'id:"' + id + '"' +
            ') { id,name,slug,image,imgOverlay,price,oldPrice,isNew,isFeatures,isSale,outofstock,description,prices { attributeValue { id,value,type {name,type}},price,oldPrice} }, photos(productId:"' + id + '") {file}, landrickRelatedProducts(id:"' + id + '") { id,slug,name,image,imgOverlay,price,oldPrice,isNew,isFeatures,isSale,outofstock, } } ');
        gclient.query({ query: query }).then(result => {
            if (result.data.slug !== undefined && result.data.slug !== null) {
                StandClient.yandexMetrika.hit('/product/' + result.data.slug + '/');
            }
            resolve(result.data);
        }).catch(error => {
            reject(error);
        });
    }),
    getAllCategories: (id) => new Promise((resolve, reject) => {
        let query = gql('query { allCategorys { id, name, parent {id} } }');
        gclient.query({ query: query }).then(result => {
            resolve(result.data);
        }).catch(error => {
            reject(error);
        });
    }),
    getRelatedProducts: (id) => new Promise((resolve, reject) => {
        let query = gql('query { landrickRelatedProducts(id:' + id + ') { id,name,image,imgOverlay,price,oldPrice,isNew,isFeatures,isSale,outofstock,slug } }');
        gclient.query({ query: query }).then(result => {
            resolve(result.data.landrickRelatedProducts);
        }).catch(error => {
            reject(error);
        });
    }),
    sendOrder: (fields, paymentType, cart, salePointId) => new Promise((resolve, reject) => {
        var newCart = [];
        Object.entries(JSON.parse(cart)).forEach(([key, value]) => {
            newCart.push({
                product: value.product.id,
                attributeValue: value.attributeValueId,
                quantity: value.quantity,
                selectedAttributes: value.selectedAttributes,
            })
        });
        if (newCart.length === 0) {
            reject('Ваша корзина пуста');
        }
        var address = fields['address'];
        var house = fields['house'];
        var apartment = fields['apartment'];
        var phone = fields['phone_mask'];
        var comment = fields['comment'];
        var email = fields['email'];
        var firstName = fields['firstName'];
        var lastName = fields['lastName'];
        var entrance = fields['entrance'];
        var streetId = fields['streetId'];
        var floor = fields['floor'];
        var prepareMoney = fields['prepareMoney'];
        var structure = fields['structure'];

        if (structure === undefined || structure === null) {
            structure = '';
        }
        if (prepareMoney === undefined || prepareMoney === null) {
            prepareMoney = '';
        }
        if (streetId === undefined || streetId === null) {
            streetId = '';
        }
        if (house === undefined || house === null) {
            house = '';
        }
        if (apartment === undefined || apartment === null) {
            apartment = '';
        }
        if (phone === undefined || phone === null) {
            phone = '';
        }
        if (comment === undefined || comment === null) {
            comment = '';
        }
        if (email === undefined || email === null) {
            email = '';
        }
        if (firstName === undefined || firstName === null) {
            firstName = '';
        }
        if (lastName === undefined || lastName === null) {
            lastName = '';
        }
        if (entrance === undefined || entrance === null) {
            entrance = '';
        }
        if (floor === undefined || floor === null) {
            floor = '';
        }

        let mutation = gql('mutation {sendOrder(' +
            'cart:"' + JSON.stringify(newCart).replaceAll('"', '\\"') + '",' +
            'address:"' + address.replaceAll('"', '\\"') + '",' +
            'house:"' + house.replaceAll('"', '\\"') + '",' +
            'apartment:"' + apartment.replaceAll('"', '\\"') + '",' +
            'phone:"' + phone + '",' +
            'streetId:"' + streetId + '",' +
            'prepareMoney:"' + prepareMoney + '",' +
            'structure:"' + structure + '",' +
            'comment:"' + comment.replaceAll('"', '\\"').replaceAll('\n', '\\n') + '",' +
            'email:"' + email + '",' +
            'firstName:"' + firstName.replaceAll('"', '\\"') + '",' +
            'lastName:"' + lastName.replaceAll('"', '\\"') + '",' +
            'entrance:"' + entrance.replaceAll('"', '\\"') + '",' +
            'floor:"' + floor.replaceAll('"', '\\"') + '",' +
            'paymentType:"' + paymentType + '",' +
            'salePointId:' + salePointId + '' +
            ') {order {id,key}, payment {url}}}'
        );
        gclient.mutate({ mutation: mutation }).then(result => {
            localStorage.setItem('cart', '{}');
            StandClient.yandexMetrika.reachGoal('sendOrder', { paymentType: paymentType, salePointId: salePointId, orderId: result.data.sendOrder.order.id });
            resolve(result.data);
        }).catch(error => {
            reject(error);
        });
    }),
    checkUserToken: () => new Promise((resolve, reject) => {
        let ClientUserToken = localStorage.getItem('ClientUserToken');
        if (ClientUserToken === '' || ClientUserToken === null || ClientUserToken === undefined) {
            reject('empty token');
        } else {
            let query = gql('query{ checkAuthManager(token:"' + ClientUserToken + '") {token, user {email, extFields,phoneStr,firstName,lastName,birthday,telegram,instagram,vkontakte,youtube,whatsApp,linkedIn,tenChat,yandexMessager,clubhouse,site}} }');
            gclient.query({ query: query }).then(result => {
                if (result.data.checkAuthManager === null) {
                    StandClient.logout();
                    reject('wrong token')
                } else {
                    localStorage.setItem('ClientUserEmail', result.data.checkAuthManager.user.email);
                    resolve(result.data);
                }
            }).catch(error => {
                reject(error);
            });
        }
    }),
    retriveUserEmail: () => new Promise((resolve, reject) => {
        let ClientUserEmail = localStorage.getItem('ClientUserEmail');
        if (ClientUserEmail !== '' && ClientUserEmail !== null && ClientUserEmail !== undefined) {
            resolve(ClientUserEmail);
        } else {
            StandClient.checkUserToken().then(result => {
                resolve(result.checkAuthManager.user.email);
            }).catch(error => {
                reject(error);
            });
        }
    }),
    register: (firstName, lastName, email, password) => new Promise((resolve, reject) => {
        if (email === '' || email === null || email === undefined) {
            reject('empty login');
        } else if (password === '' || password === null || password === undefined) {
            reject('empty password');
        } else if (firstName === '' || firstName === null || firstName === undefined) {
            reject('empty firstName');
        } else if (lastName === '' || lastName === null || lastName === undefined) {
            reject('empty lastName');
        } else {
            let query = gql('query{ registerClient(email:"' + email + '",password:"' + password + '",firstName:"' + firstName + '",lastName:"' + lastName + '") {token, user {email, extFields,phoneStr,firstName,lastName,birthday,telegram,instagram,vkontakte,youtube,whatsApp,linkedIn,tenChat,yandexMessager,clubhouse,site}}}');
            gclient.query({ query: query }).then(result => {
                localStorage.setItem('ClientUserToken', result.data.registerClient.token);
                localStorage.setItem('ClientUserEmail', result.data.registerClient.user.email);
                resolve(result.data);
            }).catch(error => {
                reject(error);
            });
        }
    }),
    auth: (login, password) => new Promise((resolve, reject) => {
        if (login === '' || login === null || login === undefined) {
            reject('empty login');
        }
        else if (password === '' || password === null || password === undefined) {
            reject('empty password');
        } else {
            let query = gql('query{ authManager(login:"' + login + '",password:"' + password + '") {token, user {email, extFields,phoneStr,firstName,lastName,birthday,telegram,instagram,vkontakte,youtube,whatsApp,linkedIn,tenChat,yandexMessager,clubhouse,site}}}');
            gclient.query({ query: query }).then(result => {
                localStorage.setItem('ClientUserToken', result.data.authManager.token);
                localStorage.setItem('ClientUserEmail', result.data.authManager.user.email);
                resolve(result.data);
            }).catch(error => {
                reject(error);
            });
        }
    }),
    logout: () => new Promise((resolve) => {
        localStorage.removeItem('ClientUserToken');
        localStorage.removeItem('ClientUserEmail');
        resolve('ok');
    }),
    saveUserProfile: (user) => new Promise((resolve, reject) => {
        const token = localStorage.getItem('ClientUserToken');
        let query = gql('query{ saveUserProfile(token:"' + token + '",first:"' + user.firstName + '",last:"' + user.lastName + '",phoneStr:"' + user.phoneStr + '",telegram:"' + user.telegram + '",instagram:"' + user.instagram + '",vkontakte:"' + user.vkontakte + '",youtube:"' + user.youtube + '",site:"' + user.site + '") {message, success} }');
        gclient.query({ query: query }).then(result => {
            resolve(result.data.saveUserProfile.message);
        }).catch(error => {
            reject(error);
        });
    }),
    saveUserProfileExtFields: (data) => new Promise((resolve, reject) => {
        const token = localStorage.getItem('ClientUserToken');
        const dd = data.replaceAll('\n', '\\n').replaceAll('\r', '').replaceAll('"', '\\"').replaceAll('\\r', '\n');
        let query = gql('query{ saveUserExts(token:"' + token + '",data:"' + dd + '"){message,success} }');
        gclient.query({ query: query }).then(result => {
            resolve(result.data.saveUserExts.message);
        }).catch(error => {
            reject(error);
        });
    }),
    getOrderByIdAndKey: (id, orderKey) => new Promise((resolve, reject) => {
        let query = gql('query {' +
            'landrickOrder(id:' + id + ',key:"' + orderKey + '") {' +
            'order { id,paymentType,comment,paymentSet {url,status} }' +
            ' customer {' +
            'firstName,lastName,phone,email}' +
            'delivery {address,house,apartment,phone,}' +
            'products {' +
            '    product { name }' +
            '    quantity,unitPrice,withDiscount' +
            '    discount {value,percent}' +
            '    attributeValue {value} } } }');
        gclient.query({ query: query }).then(result => {
            resolve(result.data.landrickOrder);
        }).catch(error => {
            reject(error);
        });
    }),
    getLastOrder: () => new Promise((resolve, reject) => {
        var prevOrders = JSON.parse(localStorage.getItem('prevOrders'));
        if (prevOrders === undefined || prevOrders === null) {
            reject('Empty order list');
        }
        if (prevOrders.length === 0) {
            reject('Empty order list');
        }
        const lastOrder = prevOrders[prevOrders.length - 1];
        StandClient.getOrderByIdAndKey(lastOrder.id, lastOrder.key).then(result => {
            resolve(result);
        }).catch(error => {
            reject(error);
        });
    }),
    getOrder: (id) => new Promise((resolve, reject) => {
        var prevOrders = null;
        try {
            prevOrders = JSON.parse(localStorage.getItem('prevOrders'))
        } catch {
            reject(`Ваш заказ #${id} не найден!`);
        }
        var orderKey = null;
        prevOrders.forEach((val) => {
            if (val.id === id) {
                orderKey = val.key;
            }
        });
        if (orderKey === null) {
            reject(`Ваш заказ #${id} не найден!`);
        }
        StandClient.getOrderByIdAndKey(id, orderKey).then(result => {
            resolve(result);
        }).catch(error => {
            reject(error);
        });
    }),
    getPage: (id) => new Promise((resolve, reject) => {
        let query = gql('query {page(id:' + id + ') { id, name, content, iframeUrl, parent { id, name, content }, slug, path, headerViewTypeName, headerViewJsonSettings, mobilePortraitHeaderViewTypeName, mobilePortraitHeaderViewJsonSettings, mobilePortraitHeaderViewEnabled, mobileLandscapeHeaderViewTypeName, mobileLandscapeHeaderViewJsonSettings, mobileLandscapeHeaderViewEnabled, blocks { block { id, name, content, blockType, params, mediaQueryDevice, mediaQueryOrientation } } } }');
        gclient.query({ query: query }).then(result => {
            StandClient.yandexMetrika.hit('/page/' + id + '/');
            resolve(result.data.page);
        }).catch(error => {
            reject(error);
        });
    }),
    getPageByPath: (path) => new Promise((resolve, reject) => {
        let query = gql('query {page(path:"' + path + '") { id, name, content, iframeUrl, parent { id, name, content }, slug, path, headerViewTypeName, headerViewJsonSettings, pageType, mobilePortraitHeaderViewTypeName, mobilePortraitHeaderViewJsonSettings, mobilePortraitHeaderViewEnabled, mobileLandscapeHeaderViewTypeName, mobileLandscapeHeaderViewJsonSettings, mobileLandscapeHeaderViewEnabled, blocks { block { id, name, content, blockType, params, mediaQueryDevice, mediaQueryOrientation, jsonValues } } } }');
        gclient.query({ query: query }).then(result => {
            // StandClient.yandexMetrika.hit('/page/'+id+'/');
            resolve(result.data.page);
        }).catch(error => {
            reject(error);
        });
    }),
    checkCart: () => {
        var cart = localStorage.getItem('cart');
        if (cart == null) {
            localStorage.setItem('cart', '{}');
        }
        try {
            JSON.parse(localStorage.getItem('cart'));
        } catch {
            localStorage.setItem('cart', '{}');
        }
        cart = JSON.parse(localStorage.getItem('cart'));
        try {
            var autoAddingProducts = StandClient.getFrontConfigureLocal().data.getFrontConfigure.autoAddingProducts;
            autoAddingProducts.forEach((val) => {
                if (Object.keys(cart).indexOf(val.id + ';null;') === -1) {
                    cart = StandClient.addToCart(val, null, 1, []);
                }
            });
        } catch (error) {
            console.log(error);
        }
        return cart;
    },
    addToCart: (product, attributeValueId, quantity, selectedAttributes) => {
        let cart = JSON.parse(localStorage.getItem('cart'));
        let selectedAttributesStr = '';
        if (selectedAttributes === undefined) {
            selectedAttributes = [];
        }
        selectedAttributes = selectedAttributes.sort((el1, el2) => {
            return el1.attributeValue.id < el2.attributeValue.id
        });
        let productBuf = product;
        selectedAttributes.forEach(pp => {
            selectedAttributesStr = selectedAttributesStr + ',' + pp.attributeValue.id;
        });
        if (cart[productBuf.id + ';' + attributeValueId + ';' + selectedAttributesStr] === undefined) {
            cart[productBuf.id + ';' + attributeValueId + ';' + selectedAttributesStr] = { product: productBuf, quantity: quantity, attributeValueId: attributeValueId, selectedAttributes: selectedAttributes };
        } else {
            cart[productBuf.id + ';' + attributeValueId + ';' + selectedAttributesStr]['quantity'] = cart[productBuf.id + ';' + attributeValueId + ';' + selectedAttributesStr]['quantity'] + quantity;
        }
        localStorage.setItem('cart', JSON.stringify(cart));
        StandClient.yandexMetrika.reachGoal('addToCart', { product: productBuf.id, quantity: quantity });
        return cart;
    },
    sendFeedBack: (text = null, fields = null, url = null, blockId = null, pageId = null) => new Promise((resolve, reject) => {
        let ftext = 'Получен фидбэк:';
        if (text !== null && text !== undefined && text !== '') {
            ftext += text;
        }
        if (fields !== null && fields !== undefined && fields !== '') {
            Object.entries(fields).forEach(([key, value]) => {
                ftext += '\n' + key + ': ' + value;
            });
        }
        if (url === null || url === undefined) {
            url = '';
        }
        const req = 'mutation {sendFeedback(' +
            'text:"' + ftext.replaceAll('"', '\\"').replaceAll('\n', '\\n') + '",' +
            ((blockId !== null && blockId !== undefined) ? ('blockId:' + blockId + ',') : ('')) +
            ((pageId !== null && pageId !== undefined) ? ('pageId:' + pageId + ',') : ('')) +
            'url:"' + url.replaceAll('"', '\\"').replaceAll('\n', '\\n') + '"' +
            ') {feedback {id}}}';
        let mutation = gql(req);
        gclient.mutate({ mutation: mutation }).then(result => {
            StandClient.yandexMetrika.reachGoal('sendFeedBack', { url: url, blockid: blockId, pageid: pageId });
            resolve(result.data);
        }).catch(error => {
            reject(error);
        });
    }),
    search: (q) => new Promise((resolve, reject) => {
        let query = gql('query{ search(q:"' + q + '"){results{results { url,title,image,highlight,type }, autocompletes, suggestions }}}');
        gclient.query({ query: query }).then(result => {
            resolve(result.data.search);
        }).catch(error => {
            reject(error);
        });
    }),

    orderSearch: (q) => new Promise((resolve, reject) => {
        let query = gql('query{ landrickOrders(search:"' + q + '"){results{results { uuid,number,createdAt,url }, autocompletes, suggestions }}}');
        gclient.query({ query: query }).then(result => {
            resolve(result.data.landrickOrders);
        }).catch(error => {
            reject(error);
        });
    }),
    streetsSearch: (search = "") => new Promise((resolve, reject) => {
        let query = gql('query{ streetsKladr(search:"' + search + '") { id,name,isDeleted,classifierId,cityKladr{ id,name } } }');
        gclient.query({ query: query }).then(result => {
            resolve(result.data.streetsKladr);
        }).catch(error => {
            reject(error);
        });
    }),
    isDeliveryAvailable: (order) => new Promise((resolve, reject) => {
        let query = gql('query{ isDeliveryAvailable(order:"' + JSON.stringify(order).replaceAll('"', '\\"') + '") { available,price } }');
        gclient.query({ query: query }).then(result => {
            resolve(result.data);
        }).catch(error => {
            reject(error);
        });
    }),
    yandexMetrika: {
        check: (obj) => {
            var res = false;
            if (obj !== undefined && obj !== null && obj !== '') {
                const yandexMetricaAccountStr = StandClient.getConfigValueByName('yandex_metrica_account');
                if (yandexMetricaAccountStr !== '') {
                    res = true;
                }
            }
            return res;
        },
        reachGoal: (target, params) => {
            if (StandClient.yandexMetrika.check(target)) {
                ym('reachGoal', target, params);
            }
        },
        hit: (url) => {
            if (StandClient.yandexMetrika.check(url)) {
                ym('hit', url);
            }
        }
    },
    sortMap: {
        'populardesc': { sortField: 'total_ordered_count', sortOrder: 'DESC' },
        'priceasc': { sortField: 'default_price', sortOrder: 'ASC' },
        'pricedesc': { sortField: 'default_price', sortOrder: 'DESC' },
        'iddesc': { sortField: 'id', sortOrder: 'DESC' },
    },
};

export default StandClient;
