import './style.css';

export default function Loader() {
    return (
        <div id="preloader" style={{position:"static"}}>
            <br /><br /><br /><br />
            <div id="status">
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>
            <br /><br /><br /><br />
        </div>
    );
}
