import { useState } from 'react';
import TextField from '@material-ui/core/TextField';
// import TextField from '@mui/material/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Autocomplete from '@mui/material/Autocomplete';
import StandClient from 'client';

const AutocompleteCustom = (props) => {
    const [options, setOptions] = useState([]);
    const defaultStreet = props.id && { id: props.id, title: props.title }
    const handlerStreetChange = (value) => {
        if (value){
            props.onChange(value)
        } else {
            props.onChange(null)
        }
    }

    const getData = (searchTerm) => {
        StandClient.streetsSearch(searchTerm).then((result) => {
            const updatedOptions = result.map((street) => {
                return { id: street.id, title: street.name };
            });
            setOptions(updatedOptions);
            }).catch((error) => {
                console.log(error);
            });
    };

    const onInputChange = (event, value, reason) => {
        if (value) {
            getData(value);
        } else {
            setOptions([]);
        }
    };

    return (
        // TODO: Подумать с Илюхой об этом
        <Autocomplete
            id="street"
            defaultValue={ defaultStreet || null }
            options={options}
            onInputChange={onInputChange}
            getOptionLabel={(option) => option.title}
            getOptionSelected={(option, value) => option.iso === value.iso}
            onChange={(event, value) => handlerStreetChange(value)}
            style={{ width: "100%" }}
            renderInput={(params) => (
                <TextField {...params} placeholder="Улица" className="form-control" variant="outlined" />
            )}
        />
    );
    }

export default AutocompleteCustom;
