import { useEffect, useState } from "react";
import { useRoutes } from 'react-router-dom';
import Layout from "./components/Layout/";
import StandClient from './client/index'
import routes from "./routes";
import Loader from "components/ui/Loader";
import Error404 from "components/pageBlockConstructor/pages/Error404";
import "assets/css/materialdesignicons.min.css";
import "./Apps.scss";
import "assets/css/colors/default.css";
import '@gravity-ui/uikit/styles/fonts.css';
import '@gravity-ui/uikit/styles/styles.css';

export default function App() {
    const [frontConfigure, setFrontConfigure] = useState(null);
    const element = useRoutes(routes);

    useEffect(() => {
        StandClient.getFrontConfigure().then(result=>{
            setFrontConfigure({ frontConfigure: result.result.data.getFrontConfigure });
        })
    }, []);

    if (frontConfigure == null) {
        return (<Loader />);
    }

    return (
        <Layout hasDarkTopBar={true}>
            {element ?? <Error404 />}
        </Layout>
    );
}
