import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import StandClient from "client";


const BlockProducts = (props) => {
    const products = JSON.parse(props.block.params);

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h5 className="mb-0">{props.block.name}</h5>
                </Col>
            </Row>
            <Row>
                {products.map((product, key) => {
                    let hasDiscount = (product.oldPrice && product.oldPrice !== product.price);
                    return (
                        <Col key={key} lg={4} md={6} xs={12} className="mt-4 pt-2">
                            <Card className="shop-list border-0 position-relative">
                                <ul className="label list-unstyled mb-0">
                                    {product.isNew && (
                                        <li>
                                            <Link to="#" className="badge badge-link rounded-pill bg-primary" >
                                                Новинка
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                                <div className="shop-image position-relative overflow-hidden rounded shadow" style={{height:'220px',}}>
                                    <Link to={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"}>
                                        <img alt={"Product"+product.id} src={product.image} className="img-fluid" />
                                    </Link>
                                    {(product.imgOverlay)?(
                                        <Link to={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="overlay-work" >
                                            <img alt={"Product"+product.id} src={product.imgOverlay} className="img-fluid" />
                                        </Link>
                                    ):(null)}
                                    <ul className="list-unstyled shop-icons">
                                        <li className="mt-2">
                                            <Link to={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="btn btn-icon btn-pills btn-soft-primary">
                                                <FeatherIcon icon="eye" className="icons" />
                                            </Link>
                                        </li>
                                        <li className="mt-2">
                                            <Link 
                                                to="#" 
                                                onClick={()=>{
                                                    StandClient.addToCart(product,null,1,[])
                                                }}
                                                className="btn btn-icon btn-pills btn-soft-warning"
                                            >
                                                <FeatherIcon icon="shopping-cart" className="icons" />
                                            </Link>
                                        </li>
                                    </ul>
                                    {product.outofstock && (
                                        <div className="overlay-work">
                                            <div className="py-2 bg-soft-dark rounded-bottom out-stock">
                                                <h6 className="mb-0 text-center">Нет в наличии</h6>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <CardBody className="content pt-4 p-2">
                                    <Link to={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="text-dark product-name h6" >
                                        {product.name}
                                    </Link>
                                    <div className="d-flex justify-content-between mt-1">
                                        <h6 className="text-muted small fst-italic mb-0 mt-1">
                                            ₽{product.price}{" "}
                                            {hasDiscount ? (
                                                <del className="text-danger ms-2">
                                                    ₽{product.oldPrice}
                                                </del>
                                            ) : null}
                                            {product.desc ? (
                                                <span className="text-success ms-1">
                                                    {product.desc}
                                                </span>
                                            ) : null}
                                        </h6>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                )}
            </Row>
        </Container>
    );
}

export default BlockProducts;
