import { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Pagination,
    PaginationItem,
    PaginationLink,
    Card,
    CardBody,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import StandClient from "client";
import ReactSearchAutocomplete from "components/Search/ReactSearchAutocomplete";
import Loader from "components/ui/Loader";

const ProductsListPage = (props) => {
    console.log("ProductsListPage init");
    const navigate = useNavigate();
    const params = useParams();
    const conf = StandClient.getFrontConfigureLocal();
    const [state, setState] = useState({
        categoryId: params.id,
        pathItems: [
            { id: 1, name: "Главная", link: "/" },
            { id: 2, name: "Категория", link: "#" },
        ],
        category: null,
        products: null,
        topProducts: [],
        currentPage: 1,
        totalProductCount: 0,
        perPage: parseInt(StandClient.getConfigValueByName('products_per_page')) || 21,
        lastPage: false,
        sortStr: 'populardesc',
        sortField: 'total_ordered_count',
        sortOrder: 'DESC',
    })

    useEffect(() => {
        loadCategory(state.categoryId);
    }, []);

    const loadCategory = (categoryId) => {
        setState(prev => {
            return {
                ...prev,
                currentPage: 1,
                lastPage: false
            }
        })
        StandClient.getProducts(categoryId, 1, state.perPage, "", state.sortField, state.sortOrder).then((result)=>{
            setState(prev => {
                return {
                    ...prev,
                    products: result.landrickProducts,
                    category: result.category,
                }
            })
            }).catch((error)=>{
                console.log(error);
            }
        );
    }

    const changeSort = (newSortStr) => {
        setState(prev => {
            return {
                ...prev,
                products: [],
                currentPage: 1,
                lastPage: false,
                sortStr: newSortStr,
                sortField: StandClient.sortMap[newSortStr].sortField,
                sortOrder: StandClient.sortMap[newSortStr].sortOrder,
            }
        })
        StandClient.getProducts(state.categoryId, 1, state.perPage, state.search, StandClient.sortMap[newSortStr].sortField, StandClient.sortMap[newSortStr].sortOrder).then(result=>{
            setState(prev => {
                return {
                    ...prev,
                    products: result.landrickProducts,
                    totalProductCount: result.landrickProductCount.count,
                }
            })
        }).catch(error=>{
            console.log("ERROR!!!");
            console.log(error);
            setState(prev => {
                return {
                    ...prev,
                    products: []
                }
            })
        });
    }

    const loadMore =() => {
        StandClient.getProducts(state.categoryId, state.currentPage+1, state.perPage, "", state.sortField, state.sortOrder).then(result=>{
            var ps = state.products;
            result.landrickProducts.forEach((val)=>{
                ps.push(val);
            });
            setState(prev => {
                return {
                    ...prev,
                    products: ps,
                    totalProductCount: result.landrickProductCount.count,
                    currentPage: state.currentPage+1
                }
            })
            }).catch(error=>{
                console.log("ERROR!!!");
                console.log(error);
                setState(prev => {
                    return {
                        ...prev,
                        lastPage: true
                    }
                })
            }
        );
    }

    const PaginatorRender = () => {
        if (!state.lastPage) {
            return (
                <Col xs={12} className="mt-4 pt-2">
                    <Pagination listClassName="justify-content-center mb-0">
                        <PaginationItem>
                            <PaginationLink to="#" onClick={(e)=>{loadMore()}}>
                                Загрузить еще <i className="mdi mdi-arrow-down" />
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </Col>
            );
        } else {
            return null;
        }
    };

    const formatResult = (item) => {
        if (item.type === 'suggest') {
            return (
                <>
                <span style={{ display: 'block', textAlign: 'left' }}><i>Вы имели в виду:</i></span>
                <span style={{ display: 'block', textAlign: 'left' }}><b>{item.name}</b></span>
                </>
            )
        }
        if (item.type === 'autocomplete') {
            return <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>;
        }
        return (
            <table>
                <tr>
                    <td style={{width:48}}><img src={item.image} width={48} /></td>
                    <td>
                        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
                        <span style={{ display: 'block', textAlign: 'left' }}>{item.price}</span>
                    </td>
                </tr>
            </table>
        )
    }

    if (state.category == null || state.products == null) {
        return <Loader />
    }
    if (state.products == []) {
        return <Row>Товаров не найдено</Row>;
    }

    return (
        <section className="section">
            <Container>
                <Row>
                    <Col lg={3} md={4} xs={12}>
                        <Card className="border-0 sidebar sticky-bar">
                            <CardBody className="p-0">
                                <div className="widget">
                                    <div id="search2" className="widget-search mb-0">
                                        <ReactSearchAutocomplete
                                            onSelect={(item) => navigate(item.url)}
                                            showNoResultsText="Нет результатов"
                                            formatResult={formatResult}
                                        />
                                    </div>
                                </div>
                                <div className="widget mt-4 pt-2">
                                    <h5 className="widget-title">Категории</h5>
                                    <ul className="list-unstyled mt-4 mb-0 blog-categories">
                                        {conf.data.rootCategorys.map((cat,i)=> {
                                            return (
                                                <li key={i}>
                                                    <a href={"/category/"+cat.id+"/"} onClick={()=>{
                                                        setState(prev => {
                                                            return {
                                                                ...prev,
                                                                products: null,
                                                                category: null,
                                                                categoryId: cat.id,
                                                            }
                                                        })
                                                        loadCategory(cat.id);
                                                        }}
                                                    >
                                                        {cat.name}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={9} md={8} xs={12} className="mt-5 pt-2 mt-sm-0 pt-sm-0">
                        <Row className="align-items-center">
                            <Col lg={8} md={7}>
                                <div className="section-title">
                                    <h5 className="mb-0">{state.category.name}</h5>
                                </div>
                            </Col>

                            <Col lg={4} md={5} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="d-flex justify-content-md-between align-items-center">
                                    <div className="form custom-form">
                                        <select className="form-select form-control" onChange={(e)=>{changeSort(e.target.value)}} >
                                            <option value="populardesc">Сначала популярные</option>
                                            <option value="priceasc">Сначала бюджетные</option>
                                            <option value="pricedesc">Сначала дорогие</option>
                                            <option value="iddesc">Сначала новые</option>
                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {state.products.map((product, key) => (
                                <Col xs={12} className="mt-4 pt-2" key={key}>
                                    <Card className="shop-list border-0 position-relative">
                                        <ul className="label list-unstyled mb-0">
                                            {product.isNew && (
                                            <li>
                                                <Link to="#" className="badge badge-link rounded-pill bg-primary" >
                                                    New
                                                </Link>
                                            </li>
                                            )}
                                            {product.isFeatures && (
                                            <li>
                                                <Link to="#" className="badge badge-link rounded-pill bg-success" >
                                                    Featured
                                                </Link>
                                            </li>
                                            )}

                                            {product.isSale && (
                                            <li>
                                                <Link to="#" className="badge badge-link rounded-pill bg-warning" >
                                                    Sale
                                                </Link>
                                            </li>
                                            )}
                                        </ul>
                                        <Row className="align-items-center g-0">
                                            <Col lg={4} md={6}>
                                                <div className="shop-image position-relative overflow-hidden">
                                                    <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"}>
                                                        <img
                                                            src={(product.image.startsWith("/")) ? (product.image) : (`/${product.image}`)}
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                    </a>
                                                    {(product.imgOverlay)?(
                                                        <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="overlay-work" >
                                                            <img
                                                                src={(product.imgOverlay.startsWith("/")) ? (product.imgOverlay) : (`/${product.imgOverlay}`)}
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                        </a>
                                                    ):(null)}
                                                    {product.isOutofStock && (
                                                        <div className="overlay-work">
                                                            <div className="py-2 bg-soft-dark rounded-bottom out-stock">
                                                                <h6 className="mb-0 text-center">
                                                                    Out of stock
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>

                                            <Col lg={8} md={6}>
                                                <CardBody className="content p-4">
                                                    <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="text-dark product-name h6" >
                                                        {product.name}
                                                    </a>
                                                    <div className="d-lg-flex align-items-center mt-2 mb-3">
                                                        <h6 className="text-muted small fst-italic mb-0 me-3">
                                                            {product.price}₽{" "}
                                                            {product.oldPrice && product.oldPrice!==product.price ? (
                                                                <del className="text-danger ms-2">
                                                                    {product.oldPrice}₽
                                                                </del>
                                                            ) : null}
                                                            {product.sucessPrice ? (
                                                                <span className="text-success ms-1">
                                                                    {product.sucessPrice}
                                                                </span>
                                                            ) : null}
                                                        </h6>
                                                    </div>
                                                    <p className="para-desc text-muted mb-1">
                                                        {product.desc}
                                                    </p>
                                                    <ul className="list-unstyled mb-0">
                                                        <li className="mt-2 list-inline-item">
                                                            <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="btn btn-icon btn-pills btn-soft-primary" >
                                                                <FeatherIcon icon="eye" className="icons" />
                                                            </a>
                                                        </li>{" "}
                                                        <li className="mt-2 list-inline-item">
                                                            <Link
                                                                to="#"
                                                                onClick={()=>{
                                                                    StandClient.addToCart(product,null,1,[]);
                                                                }}
                                                                className="btn btn-icon btn-pills btn-soft-primary"
                                                            >
                                                            <FeatherIcon
                                                                icon="shopping-cart"
                                                                className="icons"
                                                            />
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </CardBody>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            ))}
                            <PaginatorRender />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );

}

export default ProductsListPage;
