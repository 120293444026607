import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderConstructor from "components/ui/headerConstructor";
import StandClient from "client";
import AutocompleteCustom from "components/ui/AutocompleteCustom";
import { useMask } from '@react-input/mask';
import {
    Container,
    Row,
    Col,
    Table,
    Input,
    Form,
    Label,
    Button,
} from "reactstrap";

const ShopCheckouts = () => {
    const navigate = useNavigate();
    const salePoints = StandClient.getFrontConfigureLocal().data.landrickSalePoints;
    const inputRef = useMask({ mask: '+7(___)___-__-__', replacement: { _: /\d/ } });
    const [salePointId, setSalePointId] = useState(null);
    const [paymentType, setPaymentType] = useState(StandClient.getFrontConfigureLocal().data.getFrontConfigure.onlinePaymentEnabled ? "online" : "cardondelivery");
    const [deliveryAvailable, setDeliveryAvailable] = useState(null);
    const [error, setError] = useState(null);
    const pathItems =[
        { id: 1, name: "Главная", link: "/" },
        { id: 2, name: "Корзина", link: "/cart/" },
        { id: 3, name: "Заказ" },
    ];
    let localOrderFields = localStorage.getItem('orderFields');
    if (localOrderFields == null || localOrderFields === undefined) {
        localOrderFields = JSON.stringify({
            address: '',
            house: '',
            apartment: '',
            phone_mask: '',
            email: '',
            comment: '',
            firstName: '',
            lastName: '',
            floor: '',
            entrance: '',
            streetId: '',
            streetTitle: '',
            prepareMoney: '',
            structure: '',
        });
        localStorage.setItem('orderFields', localOrderFields);
    }
    const [orderFields, setOrderFields] = useState(JSON.parse(localOrderFields));


    useEffect(() => {
        if ((salePoints !== null) || (salePoints.length > 0)) {
            setSalePointId(salePoints[0].id)
        }
        checkIsDeliveryAvailable(orderFields);
    }, [salePointId]);

    const changeField = (field) => {
        const newValue = {
            ...orderFields,
            [field.target.id]: field.target.value
        }
        checkIsDeliveryAvailable(newValue);
        setOrderFields(newValue)
        localStorage.setItem('orderFields', JSON.stringify(newValue));
        setError(null);
    }

    const setPrepareMoney = (value) => {
        setOrderFields({
            ...orderFields,
            [value.target.id]: value.target.value
        })
    }

    const changeStreet = (street) => {
        const newValue = {
            ...orderFields,
            ["streetId"]: street.id,
            ["address"]: street.title
        }
        checkIsDeliveryAvailable(newValue);
        setOrderFields(newValue)
        localStorage.setItem('orderFields', JSON.stringify(newValue));
    }

    const addToMyOrder = (order) => {
        let oldOrders = [];
        let oldOrdersStr = localStorage.getItem('prevOrders');
        if (oldOrdersStr === null) {
            localStorage.setItem('prevOrders', '[]');
        } else {
            try {
                oldOrders = JSON.parse(oldOrdersStr);
            } catch {
                oldOrders = [];
                localStorage.setItem('prevOrders', '[]');
            }
        }
        oldOrders.push(order);
        localStorage.setItem('prevOrders', JSON.stringify(oldOrders));
    }

    const checkIsDeliveryAvailable = (fields) => {
        StandClient.isDeliveryAvailable(fields || orderFields).then((d) => {
            setDeliveryAvailable(d.isDeliveryAvailable.available)
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const checkout = () => {
        setError(null);
        StandClient.getFrontConfigure().then(result => {
            const messageWeAreNotWorkingNow = StandClient.getConfigValueByName('message_we_are_not_working_now');
            const siteDisabledByManager = StandClient.getConfigValueByName('site_disabled_by_manager');
            if (siteDisabledByManager === '1') {
                setError(messageWeAreNotWorkingNow);
            } else {
                if (!orderFields.phone_mask.match(/\+7\([0-9]{3}\)[0-9]{3}\-[0-9]{2}\-[0-9]{2}$/)) {
                    setError("Введите номер телефона");
                    return;
                }
                StandClient.sendOrder(orderFields, paymentType, localStorage.getItem('cart'), salePointId)
                    .then((result) => {
                        addToMyOrder(result.sendOrder.order);
                        setError(null);
                        navigate("/order/"+ result.sendOrder.order.id +"/")
                    })
                    .catch((error) => {
                        setError(error);
                    });
            }
        });
    }

    let sum = 0;
    const cart = JSON.parse(localStorage.getItem('cart'));
    Object.entries(cart).forEach(([key, value]) => {
        sum = sum + (value.product.price * value.quantity);
    });
    const conf = StandClient.getFrontConfigureLocal();
    let deliveryCost = StandClient.getConfigValueByName('delivery_cost');
    if (!deliveryCost) {
         deliveryCost = 0;
    }
    let taxCost = StandClient.getConfigValueByName('tax_cost');
    if (!taxCost) {
         taxCost = 0;
    }
    document.title = 'Заказ - ' + StandClient.getConfigValueByName('site_title');

    return (
        <React.Fragment>
            <HeaderConstructor title="Заказ" pathItems={pathItems} conf={conf} pageType="shop_checkout" />
            <section className="section">
                <Container>
                    <Row>
                        <Col lg={7} md={6}>
                            <div className="rounded shadow-lg p-4">
                                <h5 className="mb-0">Детали заказа:</h5>
                                <Form className="mt-4">
                                    <Row>
                                        <Col xs={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Ваше имя
                                                </Label>
                                                <Input
                                                    name="firstName"
                                                    id="firstName"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Имя"
                                                    value={orderFields['firstName']}
                                                    onChange={ e => changeField(e) }
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Фамилия
                                                </Label>
                                                <Input
                                                    name="lastName"
                                                    id="lastName"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Фамилия"
                                                    value={orderFields['lastName']}
                                                    onChange={(e) => changeField(e) }
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <Label className="form-label">
                                                Улица <span className="text-danger">*</span>
                                            </Label>
                                            <div className="mb-3">
                                                <AutocompleteCustom
                                                    id={orderFields['streetId'] || null}
                                                    title={orderFields['address'] || null}
                                                    onChange={(street) => {
                                                        street && changeStreet(street)
                                                    }
                                                } />
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Номер дома{" "}
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    type="number"
                                                    name="house"
                                                    id="house"
                                                    className="form-control"
                                                    placeholder="Номер дома"
                                                    value={orderFields['house']}
                                                    onChange={(e) => { changeField(e) }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Корпус \ Строение{" "}
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="structure"
                                                    id="structure"
                                                    className="form-control"
                                                    placeholder="Корпус \ Строение"
                                                    value={orderFields['structure']}
                                                    onChange={(e) => { changeField(e) }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Номер квартиры{" "}
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="apartment"
                                                    id="apartment"
                                                    className="form-control"
                                                    placeholder="Номер квартиры"
                                                    value={orderFields['apartment']}
                                                    onChange={(e) => { changeField(e) }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Подъезд{" "}
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="entrance"
                                                    id="entrance"
                                                    className="form-control"
                                                    placeholder="Подъезд"
                                                    value={orderFields['entrance']}
                                                    onChange={(e) => { changeField(e) }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Этаж{" "}
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="floor"
                                                    id="floor"
                                                    className="form-control"
                                                    placeholder="Этаж"
                                                    value={orderFields['floor']}
                                                    onChange={(e) => { changeField(e) }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Телефон <span className="text-danger">*</span>
                                                </Label>
                                                <input
                                                    ref={inputRef}
                                                    type="text"
                                                    name="phone_mask"
                                                    id="phone_mask"
                                                    className="form-control"
                                                    placeholder="Номер телефона"
                                                    value={orderFields['phone_mask']}
                                                    onChange={(e) => { changeField(e) }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Email
                                                </Label>
                                                <Input
                                                    name="email"
                                                    id="email"
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Почта"
                                                    value={orderFields['email']}
                                                    onChange={(e) => { changeField(e) }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            <div className="rounded shadow-lg p-4">
                                <Label className="form-label">Комментарий</Label>
                                <textarea
                                    name="comment"
                                    id="comment"
                                    rows="4"
                                    className="form-control"
                                    placeholder="Комментарий"
                                    value={orderFields['comment']}
                                    onChange={(e) => { changeField(e) }}
                                />
                            </div>
                        </Col>
                        <Col lg={5} md={6} className=" mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="rounded shadow-lg p-4 sticky-bar">
                                <div className="d-flex mb-4 justify-content-between">
                                    <h5>{Object.entries(cart).length} Товара</h5>
                                    <Link to="/cart/" className="text-muted h6">
                                        Детали
                                    </Link>
                                </div>
                                <div className="table-responsive">
                                    <Table className="table-end table-padding mb-0">
                                        <tbody>
                                            <tr>
                                                <td className="h6 border-0">Подитог</td>
                                                <td className="text-end fw-bold border-0">
                                                    {sum} ₽
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="h6">Стоимость доставки</td>
                                                <td className="text-end fw-bold">
                                                    {deliveryCost} ₽
                                                </td>
                                            </tr>
                                            <tr className="bg-light">
                                                <td className="h5 fw-bold">Итого</td>
                                                <td className="text-end text-primary h4 fw-bold">
                                                    {parseInt(sum) + parseInt(deliveryCost)} ₽
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    {(salePoints === null || salePoints.length < 2) ? (null) : (
                                        <ul className="list-unstyled mt-4 mb-0">
                                            <div className="d-flex mb-4 justify-content-between">
                                                <h5>Точки продаж</h5>
                                            </div>
                                            {salePoints.map((value) => {
                                                return (<li className="mt-3" key={value.id}>
                                                    <div className="form-check mb-0">
                                                        <Input className="form-check-input" defaultChecked={value.id === salePointId} type="radio" name="flexRadioDefaultPoint" onChange={(e) => { setSalePointId(value.id) }} />
                                                        <Label className="form-check-label" htmlFor="salepoint">{value.name}</Label>
                                                    </div>
                                                </li>
                                                )}
                                            )}
                                        </ul>
                                    )}
                                    <ul className="list-unstyled mt-4 mb-0">
                                        <div className="d-flex mb-4 justify-content-between">
                                            <h5>Способ оплаты</h5>
                                        </div>
                                        {((conf.data.getFrontConfigure.onlinePaymentEnabled) ? (<li className="mt-3">
                                            <div className="form-check mb-0">
                                                <Input className="form-check-input" defaultChecked type="radio" name="flexRadioDefault" id="online" onChange={() => { setPaymentType("online") }} />
                                                <Label className="form-check-label" htmlFor="online">Оплатить на сайте</Label>
                                            </div>
                                        </li>) : (null))}
                                        <li className="mt-3">
                                            <div className="form-check mb-0">
                                                <Input className="form-check-input" defaultChecked={!conf.data.getFrontConfigure.onlinePaymentEnabled} type="radio" name="flexRadioDefault" id="cardondelivery" onChange={() => { setPaymentType("cardondelivery") }} />
                                                <Label className="form-check-label" htmlFor="cardondelivery">Оплата картой при получении</Label>
                                            </div>
                                        </li>
                                        <li className="mt-3">
                                            <div className="form-check mb-0">
                                                <Input className="form-check-input" type="radio" name="flexRadioDefault" id="cashondelivery" onChange={() => { setPaymentType("cashondelivery") }} />
                                                <Label className="form-check-label" htmlFor="cashondelivery">Наличными при получении</Label>
                                                {(paymentType == "cashondelivery") &&
                                                (<div>
                                                    Нужна сдача с:
                                                    <Input
                                                        name="prepareMoney"
                                                        id="prepareMoney"
                                                        type="number"
                                                        style={{"width" : "150px"}}
                                                        placeholder="₽"
                                                        onChange={ v => setPrepareMoney(v) }
                                                    />
                                                </div>)
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="mt-4 pt-2">
                                        <div className="d-grid">
                                            {error !== '' ? (
                                                <p style={{ color: "#ff0000" }}>{error}</p>
                                            ) : (null)}
                                            {deliveryAvailable === false ? (
                                                <p style={{ color: "#ff0000" }}>Не доставляем на данный адрес</p>
                                            ) : (null)}
                                            {deliveryAvailable === null ? (
                                                <p style={{ color: "#ff0000" }}>Введите адрес</p>
                                            ) : (null)}
                                            <Button disabled={ deliveryAvailable !== true } className="btn btn-primary" onClick={(event) => {
                                                event.target.disabled = true;
                                                checkout()
                                            }} >
                                                Оформить заказ
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default ShopCheckouts;
