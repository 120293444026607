import { Col, Container, Row } from 'reactstrap';

const HeaderRiver = (props) => {
    const items = (props.settings['sliders'] !== undefined && props.settings['sliders']['items'] !== undefined)?(props.settings['sliders']['items']):([]);
    let item = items[0];
    let height = props.settings['height'];

    return (
        <section className="bg-half-170 d-table w-100 it-home"
            style={{ background: `url(${item.image}) center center`, height:height, overflow:"hidden" }}
        >
            <div className="bg-overlay" style={{backgroundColor:'#edededaa'}} />
            <Container>
                <Row className="align-items-center">
                    <Col lg={7} md={6} xs={12}>
                        <div className="title-heading">
                            <h6 className="text-black-50">
                                {item.h6}
                            </h6>
                            <h1 className="heading mb-3">
                                {item.h1}
                            </h1>
                            <p className="para-desc text-muted" dangerouslySetInnerHTML={{ __html: item.p }} />
                            <div className="mt-4 pt-2">
                                <a href={item.link} className={item.btnclass} >
                                    <i className={item.iClass} /> {item.btntext}
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HeaderRiver;
