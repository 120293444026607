import { Col, Input, Label } from "reactstrap";

const RenderAttributes = (props) => {
    const setAttributeValue = (attributeValueId) => {
        props.product.prices.forEach(price => {
            if ((price.attributeValue !== null) && (price.attributeValue.id === attributeValueId)) {
                let product = null;
                if (price.attributeValue.type.type === 'ADDITION') {
                    let selectedAttributes = props.selectedAttributes;
                    let ain = selectedAttributes.findIndex(el=> {
                        return el.attributeValue.id === price.attributeValue.id
                    });
                    if (ain>-1) {
                        selectedAttributes.splice(ain,1);
                    } else {
                        selectedAttributes.push(price);
                    }
                    product = props.product;
                    product.price = parseFloat(props.defaultPrice);
                    selectedAttributes.forEach(pp =>{
                        product.price += parseFloat(pp.price);
                    });
                    console.log("111")
                    console.log(product.price)
                    props.onSetSelectedAttributes({
                        product: product,
                        selectedAttributes: selectedAttributes
                    })
                } else {
                    product = props.product;
                    product.price = price.price;
                    product.oldPrice = price.oldPrice;
                    console.log("222")
                    console.log(product.price)
                    props.onSetAttributeValueId({
                        product: product,
                        attributeValueId: attributeValueId
                    })
                }
            }
        });
    }

    let attribName = '';
    let attribType = '';
    if (props.product.prices.length > 1) {
        props.product.prices.forEach(price => {
            if (price.attributeValue != null) {
                attribName = price.attributeValue.type.name;
                attribType = price.attributeValue.type.type;
            }
        });
    }

    if (attribType === "RADIO") {
        return (
            <Col lg={24} xs={24}>
                <div className="d-flex" style={{flexDirection:'column'}}>
                    <h6 className="mb-0">{attribName}:</h6>
                    <ul className="list-unstyled mb-0 ms-3">
                        {props.product.prices.map(price => {
                            return (
                                <li>
                                    <div className="form-check mb-0">
                                        {price.price === props.product.price ? (
                                            <Input className="form-check-input" type="radio" onClick={(el)=>{setAttributeValue(el.target.id);}} name="flexRadioDefault" id={price.attributeValue.id} defaultChecked />
                                        ) : (
                                            <Input className="form-check-input" type="radio" onClick={(el)=>{setAttributeValue(el.target.id);}} name="flexRadioDefault" id={price.attributeValue.id} />
                                        ) }
                                        <Label className="form-check-label text-muted" htmlFor={price.attributeValue.id}>{price.attributeValue.value} ({price.price}₽)
                                            {price.price !== price.oldPrice ? (<del> {price.oldPrice}₽</del>) : (null)}
                                        </Label>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    <br/>
                </div>
            </Col>);
    } else if (attribType === "ADDITION") {
        return (
            <Col lg={24} xs={24}>
                <div className="d-flex" style={{flexDirection:'column'}}>
                    <h6 className="mb-0">{attribName}:</h6>
                    <ul className="list-unstyled mb-0 ms-3">
                        {props.product.prices.map(price => {
                            if (price.attributeValue != null) {
                                return (
                                    <li key={price.attributeValue.id}>
                                        <div className="form-check mb-0">
                                            {price.price === props.product.price ? (
                                                <Input className="form-check-input" type="checkbox" onClick={(el)=>{setAttributeValue(el.target.id);}} name="flexRadioDefault" id={price.attributeValue.id} defaultChecked />
                                            ) : (
                                                <Input className="form-check-input" type="checkbox" onClick={(el)=>{setAttributeValue(el.target.id);}} name="flexRadioDefault" id={price.attributeValue.id} />
                                            ) }
                                            <Label className="form-check-label text-muted" htmlFor={price.attributeValue.id}>{price.attributeValue.value} ({price.price}₽)
                                                {price.price !== price.oldPrice ? (<del> {price.oldPrice}₽</del>) : (null)}
                                            </Label>
                                        </div>
                                    </li>
                                )
                            }
                        return null;
                        })}
                    </ul>
                    <br/>
                </div>
            </Col>
        );
    } else {
        return null;
    }
}

export default RenderAttributes;
