import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import { useMediaQuery } from 'react-responsive';
import PageBreadcrumb from "components/Shared/PageBreadcrumb";
import HeaderIndexHotel from "./HeaderIndexHotel";
import HeaderStudio from "./HeaderStudio"
import HeaderSingle from "./HeaderSingle"
import HeaderRiver from "./HeaderRiver";
import HeaderListLinks from "./HeaderListLinks"
import HeaderSoftware from "./HeaderSoftware"
import HeaderTimer from "./HeaderTimer"
import StandClient from "client";

const viewMap = {
    'default': (props)=>DefaultHeaderView(props),
    'none': ()=>HeaderNoneView(),
    'landrick_index_hotel': (props)=>HeaderIndexHotelView(props),
    'landrick_studio': (props)=>HeaderStudioView(props),
    'landrick_single': (props)=>HeaderSingleView(props),
    'river': (props)=>HeaderRiverView(props),
    'list_links': (props)=>HeaderListLinksView(props),
    'software': (props)=>HeaderSoftwareView(props),
    'timer': (props)=>HeaderTimerView(props),
    'iframe': (props)=>HeaderIframeView(props),
};

const DefaultHeaderView = (props) => {
    return (
        <div>
            <PageBreadcrumb title={props.title} pathItems={props.pathItems} conf={props.conf} />
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" ></path>
                    </svg>
                </div>
            </div>
        </div>
    );
}

const HeaderNoneView = () => {
  return null;
}

const HeaderIndexHotelView = (props) => {
    return <HeaderIndexHotel title={props.title} pathItems={props.pathItems} conf={props.conf} settings={props.settings} />;
}

const HeaderStudioView = (props) => {
    return <HeaderStudio title={props.title} pathItems={props.pathItems} conf={props.conf} settings={props.settings} />;
}

const HeaderSingleView = (props) => {
    return <HeaderSingle title={props.title} pathItems={props.pathItems} conf={props.conf} settings={props.settings} />;
}

const HeaderRiverView = (props) => {
    return <HeaderRiver title={props.title} pathItems={props.pathItems} conf={props.conf} settings={props.settings} />;
}

const HeaderListLinksView = (props) => {
    return <HeaderListLinks title={props.title} pathItems={props.pathItems} conf={props.conf} settings={props.settings} />;
}

const HeaderSoftwareView = (props) => {
    return <HeaderSoftware title={props.title} pathItems={props.pathItems} conf={props.conf} settings={props.settings} />;
}

const HeaderTimerView = (props) => {
    return <HeaderTimer title={props.title} pathItems={props.pathItems} conf={props.conf} settings={props.settings} />;
}

const HeaderIframeView = (props) => {
    if (props.settings['iframe_url'] === null || props.settings['iframe_url'] === undefined || props.settings['iframe_url'] === '') {
        return null;
    }
    let height = 420;
    if (props.settings['height'] !== null && props.settings['height'] !== undefined && props.settings['height'] !== '') {
        height = props.settings['height'];
    }
    return (
        <Container fluid>
            <Row>
                <Col xs={12} className="p-0">
                    <Card className=" map border-0">
                        <CardBody className="p-0">
                            <iframe
                                title={props.title}
                                src={props.settings['iframe_url']}
                                style={{ border: 0, height: height}}
                                allowFullScreen>
                            </iframe>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const HeaderConstructor = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 1224 });
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    let conf = props.conf;
    let title = props.title;
    let page = props.page;
    let pathItems = props.pathItems;
    let viewType = (props.viewType === undefined || props.viewType === null) ? (StandClient.getConfigValueByName('header_view_type_name','default')) : (props.viewType);
    if (viewMap[viewType.toLowerCase()] === undefined) {
        viewType = 'default';
    }
    let pageType = '';
    if (props.pageType !== null && props.pageType !== undefined) {
        pageType = props.pageType;
    }
    let json_settings = '';
    if (pageType !== '') {
        if (pageType === 'page_content') {
            viewType = props.viewType;
            json_settings = props.jsonSettings;
        } else {
            viewType = StandClient.getConfigValueByName('header_view_type_name_'+pageType,null) || viewType;
            json_settings = StandClient.getConfigValueByName('header_view_json_settings_'+pageType,null) || json_settings;
        }
    } else {
        json_settings = StandClient.getConfigValueByName('header_view_json_settings');
    }
    if (page !== null && page !== undefined) {
        if (isMobile) {
            if (isPortrait) {
                if (page.mobilePortraitHeaderViewEnabled) {
                    json_settings = page.mobilePortraitHeaderViewJsonSettings;
                    viewType = page.mobilePortraitHeaderViewTypeName;
                }
            } else {
                if (page.mobileLandscapeHeaderViewEnabled) {
                    json_settings = page.mobileLandscapeHeaderViewJsonSettings;
                    viewType = page.mobileLandscapeHeaderViewTypeName;
                }
            }
        }
    }
    let settings = null;
    try {
        settings = JSON.parse(json_settings);
    } catch {
        if (props.settings !== null && props.settings !== undefined) {
            settings = props.settings;
        } else {
            settings = {
                'sliders': {
                    'items': [
                        {
                        "id": 1,
                        "image": StandClient.getConfigValueByName('site_header_img'),
                        "h1": title,
                        "p": "",
                        "btnclass": "",
                        "btntext": "",
                        "link": "",
                        "isVideo": false,
                        },
                    ]
                }
            }
        }
    }
    viewType = viewType.toLowerCase();
    if (viewType === 'default') {
        return <DefaultHeaderView {...props} title={title} pathitems={pathItems} conf={conf} settings={settings} />
    } else if (viewType === 'none') {
        return <HeaderNoneView />
    } else if (viewType === 'landrick_index_hotel') {
        return <HeaderIndexHotelView {...props} title={title} pathitems={pathItems} conf={conf} settings={settings} />
    } else if (viewType === 'landrick_studio') {
        return <HeaderStudioView {...props} title={title} pathitems={pathItems} conf={conf} settings={settings} />
    } else if (viewType === 'landrick_single') {
        return <HeaderSingleView {...props} title={title} pathitems={pathItems} conf={conf} settings={settings} />
    } else if (viewType === 'river') {
        return <HeaderRiverView {...props} title={title} pathitems={pathItems} conf={conf} settings={settings} />
    } else if (viewType === 'list_links') {
        return <HeaderListLinksView {...props} title={title} pathitems={pathItems} conf={conf} settings={settings} />
    } else if (viewType === 'software') {
        return <HeaderSoftwareView {...props} title={title} pathitems={pathItems} conf={conf} settings={settings} />
    } else if (viewType === 'timer') {
        return <HeaderTimerView {...props} title={title} pathitems={pathItems} conf={conf} settings={settings} />
    } else if (viewType === 'iframe') {
        return <HeaderIframeView {...props} title={title} pathitems={pathItems} conf={conf} settings={settings} />
    }
    return <DefaultHeaderView {...props} title={title} pathitems={pathItems} conf={conf} settings={settings} />;
}

export default HeaderConstructor;
