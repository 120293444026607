import React, { useEffect, useReducer, useState } from "react";
import {Button, Modal} from '@gravity-ui/uikit';
import {
    Container,
    Row,
    Col,
    Input,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import StandClient from "client";
import Loader from "components/ui/Loader";
import RenderRelated from "./RenderRelated";
import RenderAttributes from "./RenderAttributes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductPageContentBlock = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [state, setState] = useState({
        productId: params.id,
        product: null,
        attributeValueId: null,
        selectedAttributes: [],
        pathItems: [
            { id: 1, name: "Главная", link: "/" },
        ],
        products: null,
        defaultPrice: null,
        topProducts: [],
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
        items: 1,
    })

    const addItem = () => {
        setState(prev => {
            return {
                ...prev,
                items: prev.items + 1
            }
        })
    };

    const removeItem = () => {
        if (state.items > 1) {
            setState(prev => {
                return {
                    ...prev,
                    items: prev.items - 1
                }
            })
        }
    };

    useEffect(() => {
        loadProduct(state.productId);
    }, []);

    const loadProduct = (productId) => {
        StandClient.getProduct(productId).then((result) => {
            let attributeValueId = null;
            let defaultPrice = 0;
            if (result.landrickProduct !== null) {
                if (result.landrickProduct.prices.length > 1) {
                    result.landrickProduct.prices.forEach(price => {
                        if (price.price === result.landrickProduct.price) {
                            if (price.attributeValue == null) {
                                attributeValueId = null;
                            } else {
                                attributeValueId = price.attributeValue.id;
                            }
                        }
                    });
                }
                result.landrickProduct.prices.forEach(price => {
                    if (price.attributeValue == null) {
                        defaultPrice = parseFloat(price.price)
                    }
                });
            }
            setState(prev => {
                return {
                    ...prev,
                    product: result.landrickProduct,
                    products: result.landrickRelatedProducts,
                    defaultPrice: parseFloat(defaultPrice),
                    photos: result.photos,
                    attributeValueId: attributeValueId,
                    selectedAttributes: [],
                    items: 1,
                    loading: false,
                }
            })
            setLoading(false)
            topScroll();
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    const topScroll = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    if (loading) {
        return (<React.Fragment>
            <section className="section pb-0">
                <Container className="mt-100 mt-60">
                    <Row>
                        <Col xs={24}>
                            <Loader />
                        </Col>
                    </Row>
                    <Row>
                        <Col><br/><br/><br/></Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>);
    }
    if (state.product === null) {
        const not_found_text = StandClient.getConfigValueByName('product_not_found_text','Товар не найден или недоступен сейчас😔');
        return (
            <React.Fragment>
                <section className="section pb-0">
                    <Container>
                        <Row className="align-items-center">
                            <Col md={5}>
                                <h1>{not_found_text}</h1>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="mt-100 mt-60">
                        <Row>
                            <Col xs={12}>
                                <h5 className="mb-0">Посмотрите другие товары</h5>
                            </Col>
                            <RenderRelated onOpenModal={() => {setModal(true)}} products={state.products} />
                        </Row>
                    </Container>
                </section>
            </React.Fragment>);
    }

    const setSelectedAttributes = (value) => {
        console.log("setSelectedAttributes")
        console.log(value)
        setState(prev => {
            return {
                ...prev,
                product: value.product,
                selectedAttributes: value.selectedAttributes
            }
        })
    }

    const setAttributeValueId = (value) => {
        setState(prev => {
            return {
                ...prev,
                product: value.product,
                attributeValueId: value.attributeValueId
            }
        })
    }

    const settings = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        draggable: true,
        pauseOnHover: true,
    };

    document.title = state.product.name + ' - ' + StandClient.getConfigValueByName('site_title');
    return (
        <React.Fragment>
            <section className="section pb-0">
                <Container>
                    <Row className="align-items-center">
                        <Col md={5}>
                            <Slider {...settings} >
                            {state.photos.map((element)=>(
                                <div key={element.file}>
                                    <img
                                        src={(element.file.startsWith("/")) ? (element.file) : (`/${element.file}`) }
                                        className="img-fluid rounded"
                                        alt="alt"
                                    />
                                </div>)
                            )}
                            </Slider>
                            {state.photos.length > 1 ? (
                                <Slider slidesToShow={2} swipeToSlide={true} focusOnSelect={true} >
                                    {state.photos.map((element)=>(
                                        <div key={element.file}>
                                            <img src={(element.file.startsWith("/")) ? (element.file) : (`/${element.file}`)}
                                                className="img-fluid" alt="" />
                                        </div>
                                    ))}
                                </Slider>
                            ) : null}
                        </Col>
                        <Col md={7} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title ms-md-4">
                                <h4 className="title">{state.product.name}</h4>
                                <h5 className="text-muted">
                                    {state.product.price}₽ {" "}
                                    {state.product.oldPrice !== state.product.price ? (
                                        <del className="text-danger ms-2">{state.product.oldPrice}₽</del>
                                    ) : null} {" "}
                                </h5>
                                <h5 className="mt-4 py-2">Описание :</h5>
                                <span className="text-muted">
                                    { React.createElement("div", { dangerouslySetInnerHTML: { __html: state.product.description } })  }
                                </span>
                                <Row className="mt-4 pt-2">
                                    { state.product.prices.length > 1 ? (
                                        <RenderAttributes 
                                            {...state}
                                            onSetSelectedAttributes={setSelectedAttributes}
                                            onSetAttributeValueId={setAttributeValueId}
                                        />
                                    ) : (null)}
                                    <Col lg={6} xs={12} className="mt-4 mt-lg-0">
                                        <div className="d-flex shop-list align-items-center">
                                            <h6 className="mb-0">Количество:</h6>
                                            <div className="qty-icons ms-3">
                                                <Input
                                                    type="button"
                                                    value="-"
                                                    onClick={removeItem}
                                                    className="minus btn btn-icon btn-soft-primary fw-bold"
                                                    readOnly
                                                />{" "}
                                                <Input
                                                    type="text"
                                                    step="1"
                                                    min="1"
                                                    name="quantity"
                                                    value={state.items}
                                                    title="Qty"
                                                    readOnly
                                                    className="btn btn-icon btn-soft-primary qty-btn quantity"
                                                />{" "}
                                                <Input
                                                    type="button"
                                                    value="+"
                                                    onClick={addItem}
                                                    className="btn btn-icon btn-soft-primary plus"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mt-4 pt-2">
                                    <Button view="action" size="xl" onClick={() => {
                                        StandClient.addToCart(state.product,state.attributeValueId,state.items, state.selectedAttributes)
                                        setModal(true)
                                    }} >Добавить в корзину</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-100 mt-60">
                    <Row>
                        <Col xs={12}>
                            <h5 className="mb-0">С этим товаром берут</h5>
                        </Col>
                        <RenderRelated onOpenModal={() => {setModal(true)}} products={state.products} />
                    </Row>
                </Container>
            </section>

            <Modal open={modal} onClose={() => setModal(false)}>
                <div style={{ padding: 10, textAlign:"center" }}>
                    <h4>Добавлено</h4>
                    <Row space="5">
                        <Col s="12" l="6">
                            <Button view="action" size="l" onClick={() => {setModal(false)}} >Продолжить заказ</Button>
                        </Col>
                        <Col s="12" l="8">
                            <Button view="action" size="l" onClick={() => {navigate("/cart/")}} >В корзину</Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default ProductPageContentBlock;
