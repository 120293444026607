
import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

const HeaderListLinks = (props) => {
    const items = (props.settings['sliders'] !== undefined && props.settings['sliders']['items'] !== undefined)?(props.settings['sliders']['items']):([]);
    const categories = (props.settings['links'] !== undefined)?(props.settings['links']):([]);
    var item = items[0];
    var height = props.settings['height'];

    return (
        <React.Fragment>
            <section
                className="bg-half-170 bg-primary d-table w-100"
                style={{ background: `url(${item.image}) center center`, backgroundSize:"cover",height:height, overflow:"hidden" }}
            >
                <div className="bg-overlay" style={{backgroundColor:'#edededaa'}} />
                <Container>
                    <Row className="mt-5 justify-content-center">
                        <Col lg={12}>
                            <div className="title-heading text-center">
                                <h1 className="heading title-dark text-black mb-3">
                                    {item.h1}
                                </h1>
                                <p className="para-desc mx-auto text-black-50" dangerouslySetInnerHTML={{ __html: item.p }} />
                                <Row className="mt-4 pt-2">
                                    {categories.map((category, key) => (
                                        <Col key={key} lg={2} md={4} xs={6} className="mt-4 pt-2">
                                            <Card className="explore-feature border-0 rounded text-center bg-white">
                                                <CardBody>
                                                    <img
                                                        src={category.img}
                                                        className="icon rounded-circle shadow-lg d-inline-block avatar avatar-md-sm"
                                                        alt="cat"
                                                        style={{height:80,width:80}}
                                                    />
                                                    <div className="content mt-3">
                                                        <h6 className="mb-0">
                                                            <a href={category.link} className="title text-dark">
                                                                {category.title}
                                                            </a>
                                                        </h6>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
                    </svg>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HeaderListLinks;
