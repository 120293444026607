import ScrollUpButton from "react-scroll-up-button";
import FeatherIcon from "feather-icons-react";
import { YMInitializer } from 'react-yandex-metrika';
import Topbar from "./Topbar";
import Footer from "./Footer";
import StandClient from "client";

const Layout = (props) => {
    const topbarViewOff = StandClient.getConfigValueByName('topbar_view_off')
    const footerViewOff = StandClient.getConfigValueByName('footer_view_off')

    // TODO: Подумать над новой метрикой, эта устарела
    const yandexMetricaAccountStr = StandClient.getConfigValueByName('yandex_metrica_account');
    let yandexMetricaAccount = null;
    if (yandexMetricaAccountStr !== '') {
        try {
            yandexMetricaAccount = parseInt(yandexMetricaAccountStr, 10);
        } catch (error) {
            yandexMetricaAccount = null;
        }
    }
    // TODO: Подумать над новой метрикой, эта устарела

    return (
        <>
            {(yandexMetricaAccount==='') ? (null) : (
                <YMInitializer accounts={[yandexMetricaAccount]} options={{webvisor: true}} version="2"/>
            )}
            {!topbarViewOff && <Topbar hasDarkTopBar={props.hasDarkTopBar} />}
                {props.children}
            {!footerViewOff && <Footer />}
            <ScrollUpButton
                ContainerClassName="classForContainer"
                style={{ height: 36, width: 36 }}
                TransitionClassName="classForTransition"
            >
                <FeatherIcon icon="arrow-up" className="icons" />
            </ScrollUpButton>
      </>
    );
}

export default Layout;
