import React from "react";
import { Container, Row, Col } from "reactstrap";

const HeaderSoftware = (props) => {
    const items = (props.settings['sliders'] !== undefined && props.settings['sliders']['items'] !== undefined)?(props.settings['sliders']['items']):([]);
    var item = items[0];
    var height = props.settings['height'];

    return (
        <React.Fragment>
            <section
                className="bg-half-260 bg-primary d-table w-100"
                style={{ background: `url(${item.image}) center center`, backgroundSize:"cover", height:height, overflow:"hidden" }}
            >
                <div className="bg-overlay" style={{backgroundColor:'#edededaa'}} />
                <Container>
                    <Row className="align-items-center position-relative mt-5" style={{ zIndex: "1" }} >
                        <Col lg="6" md="12">
                            <div className="title-heading mt-4 text-center text-lg-start">
                                <h1 className="heading title-dark text-black mb-3">
                                    {item.h1}
                                </h1>
                                <p className="para-desc mx-auto text-black-50" dangerouslySetInnerHTML={{ __html: item.p }} />
                                <div className="mt-4">
                                    <a href={item.link} className={item.btnclass} >
                                        <i className={item.iClass}></i> {item.btntext}
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" md="12" className="mt-4 pt-2">
                            <div className="position-relative">
                                <div className="software_hero">
                                    <img src={item.smallImage} className="img-fluid d-block" alt="" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor" />
                    </svg>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HeaderSoftware;
