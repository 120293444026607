import { Container } from "reactstrap";

const BlockIframe = (props) => {
    return (
        <section>
            <br />
            <Container>
                { (props.block.iframeUrl !== '') ? (<iframe title={props.block.name} src={props.block.content} width="100%" height="457" frameBorder="0" />) : null }
            </Container>
            <br />
        </section>
    );
}

export default BlockIframe;
