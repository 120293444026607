import { Container, Row, Col, Input, Label, Alert } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import StandClient from "client";

const BlockSimpleForm = (props) => {
    const cont = JSON.parse(props.block.content);
    const fields = (cont.fields)?(cont.fields):([{
        "title": "Email",
        "type": "str",
        "md": 9,
        "icon": "user",
        "value": "",
        "nolabel": true
    }]);

    let values = {};
    fields.forEach(e => {
        values[e.title] = (e.value !== undefined && e.value !== null) ? (e.value) : ("");
    });

    const [state, setState] = useState({
        btnLabel: (cont.btnLabel)?(cont.btnLabel):("Отправить"),
        sendedText: (cont.sendedText)?(cont.sendedText):("Успешно отправлено!"),
        lg: (cont.lg)?(cont.lg):(12),
        buttonxs: (cont.buttonxs)?(cont.buttonxs):(3),
        buttonMarginTop: (cont.buttonMarginTop)?(cont.buttonMarginTop):(0),
        fields: fields,
        values: values,
        sended: false,
        sending: false,
        error: false,
    });

    return (
        <section>
            <br />
            <Container>
                <Row className="justify-content-center">
                    <Col lg={12} style={{margin:"10px", textAlign:"center"}}>
                        <div dangerouslySetInnerHTML={{__html: props.page.content}} />
                    </Col>
                    <Col lg={state.lg}>
                        <div className="rounded p-4 shadow">
                            <Row>
                                <Col xs={12}>
                                    <Alert
                                        isOpen={state.sended}
                                        toggle={() => {
                                            setState(prev => {
                                                return {
                                                    ...prev,
                                                    sended: false
                                                }
                                            });
                                        }}
                                        color="primary"
                                    >
                                        {state.sendedText}
                                    </Alert>
                                    <Alert
                                        isOpen={state.error}
                                        toggle={() => {
                                            setState(prev => {
                                                return {
                                                    ...prev,
                                                    error: false
                                                }
                                            });
                                        }}
                                        color="danger"
                                    >
                                        Ошибка отправки
                                    </Alert>
                                    <Row>
                                        {state.fields.map((item, key) => (
                                            <Col md={item.md} key={key}>
                                                <div className="mb-3">
                                                    { (item.nolabel) ? (null) : (
                                                    <Label className="form-label">
                                                        {item.title}
                                                    </Label>
                                                    )}
                                                    { (item.noicon) ? (null) : (
                                                    <div className="form-icon position-relative">
                                                        <i>
                                                        <FeatherIcon
                                                            icon={item.icon}
                                                            className="fea icon-sm icons"/>
                                                        </i>
                                                    </div>
                                                    )}
                                                    { (item.type === 'str') ? (
                                                    <Input
                                                        defaultValue={item.value}
                                                        className="form-control ps-5"
                                                        placeholder={item.title}
                                                        onKeyUp={(e)=>{
                                                            let values = state.values;
                                                            values[item.title] = e.target.value;
                                                            setState(prev => {
                                                                return {
                                                                    ...prev,
                                                                    values:values
                                                                }
                                                            });
                                                        }}
                                                    />
                                                    ) : (null) }
                                                    { (item.type === 'multistr') ? (
                                                    <textarea
                                                        defaultValue={item.value}
                                                        className="form-control ps-5"
                                                        rows="4"
                                                        placeholder={item.title}
                                                        onKeyUp={(e)=>{
                                                            let values = state.values;
                                                            values[item.title] = e.target.value;
                                                            setState(prev => {
                                                                return {
                                                                    ...prev,
                                                                    values:values
                                                                }
                                                            });
                                                        }}
                                                    ></textarea>
                                                    ) : (null) }
                                                </div>
                                            </Col>
                                        ))}
                                        <Col xs={state.buttonxs}>
                                            <input
                                                type="button"
                                                name="send"
                                                disabled={state.sending}
                                                style={{width:'100%',marginTop:state.buttonMarginTop}}
                                                className="btn btn-primary"
                                                value={(state.sending)?("Отправка..."):(state.btnLabel)}
                                                onClick={(e)=>{
                                                    setState(prev => {
                                                        return {
                                                            ...prev,
                                                            sending: true,
                                                            error: false
                                                        }
                                                    });
                                                    StandClient.sendFeedBack(null, state.values, window.location.href, props.block.id, props.page.id)
                                                        .then(data=> {
                                                            setState(prev => {
                                                                return {
                                                                    ...prev,
                                                                    sending:false,
                                                                    sended: true
                                                                }
                                                            })
                                                        }).catch(error=>{
                                                            setState(prev => {
                                                                return {
                                                                    ...prev,
                                                                    sending:false,
                                                                    sended: true
                                                                }
                                                            })
                                                        });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <br />
        </section>
    );
}

export default BlockSimpleForm;
