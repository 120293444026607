import { Container, Row, Col } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import Slider from "react-slick";
import "../../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../../../node_modules/react-modal-video/scss/modal-video.scss";

const HeaderIndexHotel = (props) => {
    const items = (props.settings['sliders'] !== undefined && props.settings['sliders']['items'] !== undefined)?(props.settings['sliders']['items']):([]);
    const set = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        draggable: true,
        pauseOnHover: true,
    };

    const slides = items.map((item, key) => {
        return (
            <div key={key}>
                <li
                    className="bg-home bg-animation-left d-flex align-items-center"
                    key={key}
                    style={{ backgroundImage: 'url('+item.image+')' }}
                >
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={7} md={7} className="slider-desc">
                                <div className="title-heading position-relative mt-4" style={{ zIndex: "1" }}>
                                    <h1 className="heading mb-3" dangerouslySetInnerHTML={{ __html: item.h1 }} />
                                    <p className="para-desc" dangerouslySetInnerHTML={{ __html: item.p }} />
                                    <div className="mt-4 pt-2">
                                        <a href={item.link} id={"btn" + item.id} className={item.btnclass}>
                                            {item.isVideo && (
                                                <FeatherIcon icon="video" className="icons" />
                                            )}
                                            <i className={item.iClass} /> {item.btntext}
                                        </a>
                                        {item.isVideo && (
                                            <span className="fw-bold text-uppercase small align-middle ms-2">Watch Now</span>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </li>
            </div>
        );
    });

    let height = props.settings['height'];
    return (
        <section className="main-slider" style={{height:height,overflow:"hidden"}}>
            <Slider className="slides" {...set}>
                {slides}
            </Slider>
        </section>
    );
}

export default HeaderIndexHotel;
