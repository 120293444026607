import React from "react";
import { Container } from "reactstrap";

const BlockHtml = (props) => {
    return (
        <section>
            <br />
            <Container>
                { React.createElement("div", { dangerouslySetInnerHTML: { __html: props.block.content } }) }
            </Container>
            <br />
        </section>
    );
}

export default BlockHtml;
