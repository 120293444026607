import {BrowserRouter} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ThemeProvider} from '@gravity-ui/uikit';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <BrowserRouter>
        <ThemeProvider theme="light">
            <App />
        </ThemeProvider>
    </BrowserRouter>,
);


// TODO: Не забыть удалить либу иконок старых 
// import IconCart from '@iconscout/react-unicons/icons/uil-shopping-cart';
