import { Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import FeatherIcon from "feather-icons-react";
import StandClient from "client";
import Loader from "components/ui/Loader";

const RenderRelated = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
    };
    if (props.products === null) {
        return <Loader />;
    }
    return (
        <Col xs={12} className="mt-4">
            <Slider {...settings} className="owl-carousel owl-theme">
                {props.products.map((product) => (
                    <div key={product.id} style={{ marginLeft: 5, marginRight: 5 }}>
                        <Card className="shop-list border-0 position-relative overflow-hidden m-2">
                            <ul className="label list-unstyled mb-0">
                                {product.isNew && (
                                    <li>
                                        <Link to="#" className="badge badge-link rounded-pill bg-primary" >
                                            Новинка
                                        </Link>
                                    </li>
                                )}
                                {product.isSale && (
                                    <li>
                                        <Link to="#" className="badge badge-link rounded-pill bg-warning">
                                            Распродажа
                                        </Link>
                                    </li>
                                )}
                            </ul>
                            <div className="shop-image position-relative overflow-hidden rounded shadow" >
                                <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"}>
                                    <img
                                        alt=""
                                        height={240}
                                        src={(product.image.startsWith("/")) ? (product.image) : (`/${product.image}`)}
                                        className="img-fluid"
                                    />
                                </a>
                                <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="overlay-work">
                                    <img
                                        alt=""
                                        src={(product.imgOverlay.startsWith("/")) ? (product.imgOverlay) : (`/${product.imgOverlay}`)}
                                        className="img-fluid"
                                    />
                                </a>
                                <ul className="list-unstyled shop-icons">
                                    <li className="mt-2">
                                        <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="btn btn-icon btn-pills btn-soft-primary">
                                            <i>
                                                <FeatherIcon icon="eye" className="icons" />
                                            </i>
                                        </a>
                                    </li>
                                    <li className="mt-2">
                                        <a className="btn btn-icon btn-pills btn-soft-warning"
                                            onClick={()=>{
                                                StandClient.addToCart(product,null,1,[])
                                                props.onOpenModal()
                                            }}
                                        >
                                            <i>
                                                <FeatherIcon icon="shopping-cart" className="icons" />
                                            </i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <CardBody className="content pt-4 p-2">
                                <a
                                    href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"}
                                    className="text-dark product-name h6"
                                >
                                    {product.name}
                                </a>
                                <div className="d-flex justify-content-between mt-1">
                                    <h6 className="text-muted small fst-italic mb-0 mt-1">
                                        {product.price}₽{" "}
                                        {product.oldPrice !== product.price ? (
                                            <del className="text-danger ms-2">
                                                {product.oldPrice}₽
                                            </del>
                                        ) : null}
                                        {product.desc ? (
                                            <span className="text-success ms-1">
                                                {product.desc}
                                            </span>
                                        ) : null}
                                    </h6>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                ))}
            </Slider>
        </Col>
    );
}

export default RenderRelated;
