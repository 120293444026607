import React, { useEffect, useReducer, useState } from "react";
import { Container, Row, Col, Table, Input } from "reactstrap";
import { Link } from "react-router-dom";
import HeaderConstructor from "components/ui/headerConstructor";
import StandClient from "client";

const ShopCart = () => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [items, setItems] = useState([])
    const pathItems = [
        { id: 1, name: "Главная", link: "/" },
        { id: 2, name: "Корзина", link: "#" }
    ]

    useEffect(() => {
        let cart = StandClient.checkCart();
        let items = [];
        Object.entries(cart).forEach(([key, value])=>{
            let nn = value.product.name;
            value.selectedAttributes.forEach(el => {
                nn = nn + ' + ' + el.attributeValue.value;
            });
            items.push({
                id: value.product.id,
                image: value.product.image,
                name: nn,
                price: value.product.price,
                qty: value.quantity,
                attributeValueId: value.attributeValueId,
                key: key,
            });
        });
        setItems(items)
    }, []);

    const removeCartItem = (itemId) => {
        let filtered = items.filter(function (item) {
            return item.key !== itemId;
        });
        setItems(filtered)
        let cart = JSON.parse(localStorage.getItem('cart'));
        delete cart[itemId];
        localStorage.setItem('cart', JSON.stringify(cart));
    };

    const addItem = (itemId) => {
        let newItems = items;
        console.log(itemId)
        newItems.map((item, key) =>
            item.key === itemId ? (item.qty = item.qty + 1) : false
        );
        setItems(newItems);
        let cart = JSON.parse(localStorage.getItem('cart'));
        cart[itemId].quantity = cart[itemId].quantity + 1;
        localStorage.setItem('cart', JSON.stringify(cart));
        forceUpdate();
    };

    const removeItem = (itemId) => {
        let newItems = items;
        newItems.map((item, key) => {
            if (item.key === itemId && item.qty > 1) {
                return (item.qty = item.qty - 1);
            } else {
                return false;
            }
        });
        setItems(newItems);
        let cart = JSON.parse(localStorage.getItem('cart'));
        if(cart[itemId].quantity > 1) { 
            cart[itemId].quantity = cart[itemId].quantity - 1;
            localStorage.setItem('cart', JSON.stringify(cart));
        };
        forceUpdate();
    };

    let sum = 0;
    const cart = JSON.parse(localStorage.getItem('cart'));
    Object.entries(cart).forEach(([key, value])=>{
        sum = sum + (value.product.price * value.quantity);
    });
    const conf = StandClient.getFrontConfigureLocal();
    let deliveryCost = StandClient.getConfigValueByName('delivery_cost');
    if (!deliveryCost) {
        deliveryCost = 0;
    }
    let taxCost = StandClient.getConfigValueByName('tax_cost');
    if (!taxCost) {
        taxCost = 0;
    }
    document.title = 'Корзина - ' + StandClient.getConfigValueByName('site_title');
    return (
        <React.Fragment>
            <HeaderConstructor title="Корзина" pathItems={pathItems} conf={conf} pageType="shop_cart" />
            <section className="section">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="table-responsive bg-white shadow">
                                <Table className="table-center table-padding mb-0">
                                    <thead>
                                        <tr>
                                            <th className="py-3 border-bottom" style={{ minWidth: "20px" }}></th>
                                            <th className="py-3 border-bottom" style={{ minWidth: "300px" }}>
                                                Товар
                                            </th>
                                            <th className="text-center py-3 border-bottom" style={{ minWidth: "160px" }} >
                                                Цена
                                            </th>
                                            <th className="text-center py-3 border-bottom" style={{ minWidth: "180px" }} >
                                                Количество
                                            </th>
                                            <th className="text-center py-3 border-bottom" style={{ minWidth: "160px" }} >
                                                Сумма
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, key) => (
                                            <tr key={key} className="shop-list">
                                                <td className="h6">
                                                    <Link to="#" onClick={() => removeCartItem(item.key)} className="text-danger" >
                                                        X
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        {item.image && 
                                                            <img
                                                                src={"/"+item.image}
                                                                className="img-fluid avatar avatar-small rounded shadow"
                                                                style={{ height: "auto" }}
                                                                alt=""
                                                            />
                                                        }
                                                        <h6 className="mb-0 ms-3">{item.name}</h6>
                                                    </div>
                                                </td>
                                                <td className="text-center">{item.price}₽</td>
                                                    <td className="text-center qty-icons">
                                                        <Input
                                                            type="button"
                                                            value="-"
                                                            onClick={() => removeItem(item.key)}
                                                            className="minus btn btn-icon btn-soft-primary"
                                                            readOnly
                                                        />{" "}
                                                        <Input
                                                            type="text"
                                                            step="1"
                                                            min="1"
                                                            name="quantity"
                                                            value={item.qty}
                                                            title="Qty"
                                                            readOnly
                                                            className="btn btn-icon btn-soft-primary qty-btn quantity"
                                                        />{" "}
                                                        <Input
                                                            type="button"
                                                            value="+"
                                                            onClick={() => addItem(item.key)}
                                                            readOnly
                                                            className="plus btn btn-icon btn-soft-primary"
                                                        />
                                                    </td>
                                                <td className="text-center fw-bold">
                                                    {item.qty * item.price}₽
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8} md={6} className="mt-4 pt-2">
                            <Link to="/" className="btn btn-primary">
                                В магазин
                            </Link>{" "}
                        </Col>
                        <Col lg={4} md={6} className="ms-auto mt-4 pt-2">
                            <div className="table-responsive bg-white rounded shadow">
                                <Table className="table-center table-padding mb-0">
                                    <tbody>
                                        <tr>
                                            <td className="h6">Подитог</td>
                                            <td className="text-center fw-bold">{sum} ₽</td>
                                        </tr>
                                        <tr>
                                            <td className="h6">Доставка</td>
                                            <td className="text-center fw-bold">{deliveryCost} ₽</td>
                                        </tr>
                                        <tr>
                                            <td className="h6">Налог</td>
                                            <td className="text-center fw-bold">{taxCost} ₽</td>
                                        </tr>
                                        <tr className="bg-light">
                                            <td className="h6">Итого</td>
                                            <td className="text-center fw-bold">{parseInt(sum)+parseInt(deliveryCost)+parseInt(taxCost)} ₽</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className="mt-4 pt-2 text-end">
                                <Link to="/checkout/" className="btn btn-primary">
                                    Перейти к заказу
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default ShopCart;
