import { useEffect, useReducer, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Trolley, Person } from '@gravity-ui/icons';
import StandClient from 'client';
import { Button } from "@gravity-ui/uikit";
import './style.css';


const Cart = () => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    let sum = 0;
    const navigate = useNavigate();
    const [dropdownOpenShop, setDropdownOpenShop] = useState(false);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [userEmail, setUserEmail] = useState(null);
    const cart = StandClient.checkCart();
    const auth = StandClient.getConfigValueByName('auth_enabled', '0');
    const cartDisabled = StandClient.getConfigValueByName('cart_disabled', '0') === '1';
    const authEnabled = StandClient.getConfigValueByName('auth_enabled', '0') === '1';

    useEffect(() => {
        if (authEnabled) {
            StandClient.checkUserToken().then(result=>{
                setUserEmail(result.checkAuthManager.user.email)
            });
        }
    }, []);

    const logout = () => {
        StandClient.logout().then(result=>{
            window.location.replace('/');
        }).catch(error=>{
            console.log(error);
        });
    }

    const toggleDropdownShop = () => {
        setDropdownOpenShop((prev) => {
            return !prev
        })
    };

    const toggleDropdownIsOpen = () => {
        setDropdownIsOpen((prev) => {
            return !prev
        })
    };

    const cleanCart = () => {
        localStorage.setItem('cart', '{}');
        forceUpdate();
    }

    let cartCount = 0;
    Object.entries(cart).map(([key, value])=>{
        // Убрать приборы из счёта в корзине
        if (key != "642;null;") {
            cartCount += value.quantity;
        }
    })

    return (
        <div>
            {(!cartDisabled)?(
                <Link to="/cart/" className="btn btn-primary cart-link" >
                    <Trolley />Корзина
                </Link>
            ):(null)}
            <ul className="buy-button list-inline mb-0">
                { " " } 
                {(!cartDisabled)?(
                    <li className="list-inline-item mb-0 pe-1">
                        <Dropdown
                            isOpen={dropdownOpenShop}
                            toggle={toggleDropdownShop}
                        >
                            <DropdownToggle
                                type="button"
                                className="btn btn-icon btn-primary "
                            >
                                <Trolley /><span>{cartCount}</span>
                            </DropdownToggle>
                            <DropdownMenu
                                direction="left"
                                className="dd-menu bg-white shadow rounded border-0 mt-3 p-4"
                                style={{ width: "720px" }}
                            >
                                <div className="pb-4">
                                    {(Object.entries(cart).length === 0) ? (<div className="text-dark mb-0">Корзина пуста</div>) : (null)}
                                    {Object.entries(cart).map(([key, value])=>{
                                        let nn = value.product.name;
                                        value.selectedAttributes.forEach(el => {
                                            nn = nn + ' + ' + el.attributeValue.value;
                                        });
                                        sum = sum + (value.product.price*value.quantity);
                                        return (
                                            <Link key={value.product.id} to={"/product/"+value.product.id+"/"} className="media align-items-center" style={{display: 'flex', flexDirection: 'row'}}>
                                                {value.product.image && 
                                                    <img
                                                        src={"/"+value.product.image}
                                                        className="shadow rounded"
                                                        style={{ maxWidth: "64px" }}
                                                        alt=""
                                                    />
                                                }
                                                <div className="flex-1 text-start ms-3">
                                                    <h6 className="text-dark mb-0">{nn}</h6>
                                                    <p className="text-muted mb-0">{value.product.price}₽ X {value.quantity} = </p><p className="text-dark mb-0">{(value.product.price*value.quantity)}₽</p>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </div>
                                <div className="media align-items-center justify-content-between pt-4 border-top">
                                    <h6 className="text-dark mb-0">Сумма:</h6>
                                    <h6 className="text-dark mb-0">{sum}₽</h6>
                                </div>
                                <div className="mt-3 text-center">
                                    <Button className="me-4" view="action" size="xl" onClick={() => {navigate("cart")}} >Корзина</Button>
                                    <Button className="me-4" view="action" size="xl" onClick={() => {navigate("checkout")}} >Заказать</Button>
                                    <Button className="me-4 btn_float_right" view="outlined-danger" size="xl" onClick={() => {cleanCart()}} >Очистить корзину</Button>
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                ):(null)}
                {(auth==='1' && userEmail !== null)?(
                    <li className="list-inline-item mb-0">
                        <Dropdown
                            color="primary"
                            isOpen={dropdownIsOpen}
                            toggle={toggleDropdownIsOpen}
                        >
                            <DropdownToggle
                                type="button"
                                className="btn btn-icon btn-primary"
                            >
                                <Person />
                            </DropdownToggle>
                            <DropdownMenu
                                direction="left"
                                className="dd-menu bg-white shadow rounded border-0 mt-3 py-3"
                                style={{ width: "200px" }}
                            >
                                <a className="dropdown-item text-dark" href="/profile/">
                                    <i className="uil uil-user align-middle me-1"></i>{" "}
                                    {userEmail}
                                </a>
                                <div className="dropdown-divider my-3 border-top"></div>
                                <Link className="dropdown-item text-dark" to="#" onClick={logout}>
                                    <i className="uil uil-sign-out-alt align-middle me-1"></i>{" "}
                                    Выйти
                                </Link>
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                ):(null)}
                {(auth === '1' && userEmail === null)?(
                    <a href="/auth/" className="btn btn-icon btn-primary">
                        <Person />
                    </a>
                ):(null)}
            </ul>
        </div>
    );
}

export default Cart;
