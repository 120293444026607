import { Container, Row, Col } from "reactstrap";

const HeaderStudio = (props) => {
    const items = (props.settings['sliders'] !== undefined && props.settings['sliders']['items'] !== undefined)?(props.settings['sliders']['items']):([]);
    if (items.length < 1) {
        return (null);
    }
    let item = items[0];
    let containerMarginTop = 0;
    if (item['containerMarginTop'] !== null && item['containerMarginTop'] !== undefined) {
        containerMarginTop = item['containerMarginTop'];
    }
    let containerBackground = '#ffffff';
    if (item['containerBackground'] !== null && item['containerBackground'] !== undefined) {
        containerBackground = item['containerBackground'];
    }
    let height = props.settings['height'];

    return (
        <section
            className="bg-half-170 d-table w-100"
            style={{ background: `url(${item.image}) center center`, backgroundSize:"cover", height:height, overflow:"hidden" }}
            id="home"
        >
            <Container style={{marginTop:containerMarginTop}}>
                <Row className="position-relative align-items-center pt-4">
                    <Col lg={{ size: 7, offset: 5 }}>
                        <div className="title-heading studio-home rounded shadow mt-5" style={{background: containerBackground}}>
                            <h1 className="heading mb-3">
                                {item.h1}
                            </h1>
                            <p className="para-desc" dangerouslySetInnerHTML={{ __html: item.p }} />
                            <div className="mt-4">
                                <a href={item.link} className={item.btnclass}>
                                    <i className={item.iClass} /> {item.btntext}
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default HeaderStudio;
