import { 
    Row,
    Col,
    Pagination,
    PaginationItem,
    PaginationLink,
    Card,
    CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import StandClient from "client";
import Loader from "components/ui/Loader";

const Products = (props) => {
    if (props.state.products === null) {
        return (
            <Row>
                <Loader/>
            </Row>
        )
    }

    if (props.state.products == []) {
        return (
            <Row>
                Товаров не найдено
            </Row>
        );
    }

    return (
        <Row>
            {props.state.products.map((product, key) => {
                let hasDiscount = (product.oldPrice && product.oldPrice !== product.price);
                return (
                    <Col key={key} lg={4} md={6} xs={12} className="mt-4 pt-2">
                        <Card className="shop-list border-0 position-relative">
                            <ul className="label list-unstyled mb-0">
                                {product.isNew && (
                                    <li>
                                        <Link to="#" className="badge badge-link rounded-pill bg-primary" >
                                            Новинка
                                        </Link>
                                    </li>
                                )}
                                {product.isFeatures && (
                                    <li>
                                        <Link to="#" className="badge badge-link rounded-pill bg-success" >
                                            Featured
                                        </Link>
                                    </li>
                                )}
                            </ul>
                            <div className="shop-image position-relative overflow-hidden rounded shadow">
                                <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"}>
                                    <img
                                        height={220}
                                        alt={"Product"+product.id}
                                        src={product.image}
                                        className="img-fluid"
                                    />
                                </a>
                                {(product.imgOverlay)?(
                                <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="overlay-work" >
                                    <img
                                        height={220}
                                        alt={"Product"+product.id}
                                        src={product.imgOverlay}
                                        className="img-fluid"
                                    />
                                </a>
                                ):(null)}
                                <ul className="list-unstyled shop-icons">
                                    <li className="mt-2">
                                        <a href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"} className="btn btn-icon btn-pills btn-soft-primary">
                                            <FeatherIcon icon="eye" className="icons" />
                                        </a>
                                    </li>
                                    <li className="mt-2">
                                        <Link 
                                            to="#" 
                                            onClick={()=>{
                                                StandClient.addToCart(product,null,1,[]);
                                            }}
                                            className="btn btn-icon btn-pills btn-soft-warning"
                                        >
                                            <FeatherIcon
                                                icon="shopping-cart"
                                                className="icons"
                                            />
                                        </Link>
                                    </li>
                                </ul>
                                {product.outofstock && (
                                    <div className="overlay-work">
                                        <div className="py-2 bg-soft-dark rounded-bottom out-stock">
                                            <h6 className="mb-0 text-center">Нет в наличии</h6>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <CardBody className="content pt-4 p-2">
                                <a
                                    href={"/product/"+(product.slug === '' ? product.id : product.slug)+"/"}
                                    className="text-dark product-name h6" >
                                    {product.name}
                                </a>
                                <div className="d-flex justify-content-between mt-1">
                                    <h6 className="text-muted small fst-italic mb-0 mt-1">
                                    ₽{product.price}{" "}
                                    {hasDiscount ? (
                                        <del className="text-danger ms-2">
                                            ₽{product.oldPrice}
                                        </del>
                                    ) : null}
                                    {product.desc ? (
                                        <span className="text-success ms-1">
                                            {product.desc}
                                        </span>
                                    ) : null}
                                    </h6>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )
            })}
            {(!props.state.lastPage) ?
                (<Col xs={12} className="mt-4 pt-2">
                    <Pagination listClassName="justify-content-center mb-0">
                        <PaginationItem>
                            <PaginationLink to="#" onClick={(e)=>{props.loadMore()}}>
                                Загрузить еще <i className="mdi mdi-arrow-down"></i>
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </Col>):
            (null)}
        </Row> 
    );
}

export default Products;
