
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderConstructor from "components/ui/headerConstructor";
import StandClient from "client";
import Loader from "components/ui/Loader";
import PaymentInfo from "components/ui/PaymentInfo";
import {
    Container,
    Row,
    Col,
} from "reactstrap";

const OldOrderDetail = (props) => {
    const params = useParams();
    const [order, setOrder] = useState(null);
    const pathItems = [
        { id: 1, name: "Главная", link: "/" },
        { id: 2, name: `Заказ №${params.id}` }
    ]

    useEffect(() => {
        StandClient.getOrder(params.id)
            .then((result) => {
                setOrder(result)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    if (order === null) {
        return <Loader />;
    }

    const conf = StandClient.getFrontConfigureLocal();
    return (
        <React.Fragment>
            <HeaderConstructor title={"Заказ №"+order.order.id} pathItems={pathItems} conf={conf} pageType="shop_old_order" />
            <section className="section">
                <Container>
                    <Row>
                        <Col lg={7} md={6}>
                            <div className="rounded shadow-lg p-4">
                                <h5 className="mb-0">Номер Вашего заказа {order.order.id}</h5>
                                <PaymentInfo {...order} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default OldOrderDetail;
