import { useNavigate } from 'react-router-dom';
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import img404 from "assets/images/404.svg"
import './style.css';


export default function Error404({description = "Страница не найдена"}) {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div className="back-to-home rounded d-none d-sm-block">
                <Link to="/index" className="btn btn-icon btn-soft-primary">
                    <i>
                        <FeatherIcon icon="home" className="icons" />
                    </i>
                </Link>
            </div>
            <section className="bg-home d-flex align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} md={12} className="text-center">
                            <img src={img404} className="img-fluid" alt="" />
                            <div className="text-uppercase mt-4 display-3">
                                О нет
                            </div>
                            <div className="text-capitalize text-dark mb-4 error-page">
                                {description}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="text-center">
                            <Link
                                to="#"
                                onClick={() => navigate(-1)}
                                className="btn btn-outline-primary mt-4"
                            >
                                Назад
                            </Link>{" "}
                            <Link to="/" className="btn btn-primary mt-4 ms-2">
                                На главную
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}
