
import React, {
  ChangeEvent,
  FocusEvent,
  FocusEventHandler,
  KeyboardEvent,
  useEffect,
  useState
} from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { DefaultTheme, defaultTheme } from './config' //defaultFuseOptions
import { debounce } from './utils'
import Results, { Item } from './Results'
import SearchInput from './SearchInput'
import StandClient from '../../client'

export const DEFAULT_INPUT_DEBOUNCE = 1 //200
export const MAX_RESULTS = 10

class SearchResult {
  id: string
  name: string
  type: string
  price?: string = ''
  image?: string = ''
  url?: string = ''

  constructor(id: string, name: string, type: string) {
    this.id = id
    this.name = name
    this.type = type
  }
}

export interface ReactSearchAutocompleteProps<T> {
  items: T[]
  inputDebounce?: number
  onSearch?: (keyword: string) => void
  setItems?: (results: T[]) => void
  onHover?: (result: T) => void
  onSelect?: (result: SearchResult) => void
  onFocus?: FocusEventHandler<HTMLInputElement>
  onClear?: Function
  showIcon?: boolean
  showClear?: boolean
  maxResults?: number
  placeholder?: string
  autoFocus?: boolean
  styling?: DefaultTheme
  resultStringKeyName?: string
  inputSearchString?: string
  formatResult?: Function
  showNoResults?: boolean
  showNoResultsText?: string
  showItemsOnFocus?: boolean
}

export default function ReactSearchAutocomplete<T>({
  items = [],
  inputDebounce = DEFAULT_INPUT_DEBOUNCE,
  onSearch = () => { },
  onHover = () => { },
  onSelect = () => { },
  onFocus = () => { },
  setItems = () => { },
  onClear = () => { },
  showIcon = true,
  showClear = true,
  maxResults = MAX_RESULTS,
  placeholder = '',
  autoFocus = false,
  styling = {},
  resultStringKeyName = 'name',
  inputSearchString = '',
  formatResult,
  showNoResults = true,
  showNoResultsText = 'No results',
  showItemsOnFocus = false
}: ReactSearchAutocompleteProps<T>) {
  const theme = { ...defaultTheme, ...styling }

  const [searchString, setSearchString] = useState<string>(inputSearchString)
  const [results, setResults] = useState<any[]>([])
  const [highlightedItem, setHighlightedItem] = useState<number>(0)
  const [showNoResultsFlag, setShowNoResultsFlag] = useState<boolean>(false)
  const [hasFocus, setHasFocus] = useState<boolean>(false)

  useEffect(() => {
    setSearchString(inputSearchString)
    const timeoutId = 0;
    return () => clearTimeout(timeoutId)
  }, [inputSearchString])


  useEffect(() => {
    if (
      showNoResults &&
      searchString.length > 0 &&
      results.length === 0
    ) {
      setShowNoResultsFlag(true)
    } else {
      setShowNoResultsFlag(false)
    }
  }, [showNoResults, searchString, results])

  useEffect(() => {
    if (showItemsOnFocus && results.length === 0 && searchString.length === 0 && hasFocus) {
      setResults(items.slice(0, maxResults))
    }
  }, [showItemsOnFocus, results, searchString, hasFocus])

  useEffect(() => {
    const handleDocumentClick = () => {
      eraseResults()
      setHasFocus(false)
    }

    document.addEventListener('click', handleDocumentClick)

    return () => document.removeEventListener('click', handleDocumentClick)
  }, [])

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    onFocus(event)
    setHasFocus(true)
  }

  const callOnSearch = (keyword: string) => {
    if (keyword.length == 0) {
      setResults([]);
      return
    }
    StandClient.search(keyword).then(result => {
      let items: SearchResult[] = [];
      let id = 0;
      if (result.results.results.length === 0) {
        if (result.results.autocompletes.length === 0) {
          result.results.suggestions.forEach((element: any) => {
            id += 1;
            var buf: SearchResult = new SearchResult(id.toString(), element, 'suggest');
            items.push(buf);
          });
        } else {
          result.results.autocompletes.forEach((element: any) => {
            id += 1;
            var buf: SearchResult = new SearchResult(id.toString(), element, 'autocomplete');
            items.push(buf);
          });
        }
      } else {
        result.results.results.forEach((element: any) => {
          id += 1;
          var buf: SearchResult = new SearchResult(id.toString(), element.title, element.type);
          buf.image = element.image;
          buf.price = element.price;
          buf.url = element.url;
          items.push(buf);
        });
      }
      setResults(items);
    }).catch(error => {
      console.log("ERROR!!!");
      console.log(error);
    });
  }

  const handleOnSearch = React.useCallback(
    inputDebounce > 0
      ? debounce((keyword: string) => callOnSearch(keyword), inputDebounce)
      : (keyword: string) => callOnSearch(keyword),
    [items]
  )

  const handleOnClick = (result: SearchResult) => {
    eraseResults()
    setSearchString(result.name)
    setHighlightedItem(0)
    checkSelectedResult(result)
  }

  const handleSetSearchString = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const keyword = target.value

    setSearchString(keyword)
    handleOnSearch(keyword)
  }

  const eraseResults = () => {
    setResults([])
  }

  const checkSelectedResult = (item: SearchResult) => {
    if (item.type == 'autocomplete' || item.type == 'suggest') {
      callOnSearch(item.name);
    } else {
      setSearchString('');
      setResults([]);
      onSelect(item);
    }
  }

  const handleSetHighlightedItem = ({
    index,
    event
  }: {
    index?: number
    event?: KeyboardEvent<HTMLInputElement>
  }) => {
    let itemIndex = 0

    const setValues = (index: number) => {
      setHighlightedItem(index)
      onHover(results[index])
    }

    if (index !== undefined) {
      setHighlightedItem(index)
      onHover(results[index])
    } else if (event) {
      switch (event.key) {
        case 'Enter':
          if (results.length > 0) {
            event.preventDefault()
            setSearchString(results[highlightedItem][resultStringKeyName])
            setHighlightedItem(0)
            checkSelectedResult(results[highlightedItem])
          }
          eraseResults()
          break
        case 'ArrowUp':
          event.preventDefault()
          itemIndex = highlightedItem > 0 ? highlightedItem - 1 : results.length - 1
          setValues(itemIndex)
          break
        case 'ArrowDown':
          event.preventDefault()
          itemIndex = highlightedItem < results.length - 1 ? highlightedItem + 1 : 0
          setValues(itemIndex)
          break
        default:
          break
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledReactSearchAutocomplete>
        <div className="wrapper">
          <SearchInput
            searchString={searchString}
            setSearchString={handleSetSearchString}
            autoFocus={autoFocus}
            onFocus={handleOnFocus}
            onClear={onClear}
            placeholder={placeholder}
            showIcon={showIcon}
            showClear={showClear}
            setHighlightedItem={handleSetHighlightedItem}
          />
          <Results
            results={results}
            onClick={handleOnClick}
            setSearchString={setSearchString}
            showIcon={showIcon}
            maxResults={maxResults}
            resultStringKeyName={resultStringKeyName}
            formatResult={formatResult}
            highlightedItem={highlightedItem}
            setHighlightedItem={handleSetHighlightedItem}
            showNoResultsFlag={showNoResultsFlag}
            showNoResultsText={showNoResultsText}
          />
        </div>
      </StyledReactSearchAutocomplete>
    </ThemeProvider>
  )
}

const StyledReactSearchAutocomplete = styled.div`
  position: relative;

  height: ${(props) => parseInt(props.theme.height) + 2 + 'px'};

  > .wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;

    border: ${(props) => props.theme.border};
    border-radius: ${(props) => props.theme.borderRadius};

    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.color};

    font-size: ${(props) => props.theme.fontSize};
    font-family: ${(props) => props.theme.fontFamily};

    z-index: ${(props) => props.theme.zIndex};

    &:hover {
      box-shadow: ${(props) => props.theme.boxShadow};
    }
    &:active {
      box-shadow: ${(props) => props.theme.boxShadow};
    }
    &:focus-within {
      box-shadow: ${(props) => props.theme.boxShadow};
    }
  }
`
