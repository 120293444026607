import { Container, Row, Col } from "reactstrap";
import ctaImg from "assets/images/shop/cta.jpg";

const BlockSimpleParalax = (props) => {
    const cont = JSON.parse(props.block.content);

    return (
        <Container fluid className="mt-100 mt-60">
            <div className="rounded py-5" style={{ background: (cont.background !== undefined)?(`url(${cont.background}) fixed`):(`url(${ctaImg}) fixed`) }}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="section-title">
                                <h2 className="fw-bold mb-4">
                                    {cont.title}
                                </h2>
                                <p className="para-desc para-white text-muted mb-0">
                                    {cont.body}
                                </p>
                                {(cont.buttonLabel === undefined || cont.buttonUrl === undefined)?(null):(
                                    <div className="mt-4">
                                        <a href={cont.buttonUrl} className="btn btn-primary">
                                            {cont.buttonLabel}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Container>
    );
}

export default BlockSimpleParalax;
