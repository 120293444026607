const PaymentInfo = (props) => {
    if (props.order.paymentType === 'ONLINE') {
        let paymentUrl = '';
        const payments = props.order.paymentSet;
        if (payments.length > 0) {
            paymentUrl = payments[0].url;
        }
        return (
            <p>Выбранный тип оплаты: Онлайн<br />
                <a href={paymentUrl}>Перейти к оплате</a>
            </p>
        );
    }
    if (props.order.paymentType === 'CARDONDELIVERY') {
        return <p>Выбранный тип оплаты: Картой при получении</p>;
    }
    if (props.order.paymentType === 'CASHONDELIVERY') {
        return <p>Выбранный тип оплаты: Наличными при получении<br /></p>;
    }
    return <p>Выбранный тип оплаты: {props.order.paymentType}</p>;
}

export default PaymentInfo;