import { Container, Row, Col } from "reactstrap";

const HeaderSingle = (props) => {
    const items = (props.settings['sliders'] !== undefined && props.settings['sliders']['items'] !== undefined)?(props.settings['sliders']['items']):([]);
    if (items.length < 1) {
        return (null);
    }
    let item = items[0];
    if (this.state.items.length > 1) {
        if (item.smallImage === null || item.smallImage === undefined || item.smallImage === '') {
            let item2 = this.state.items[1];
            if (item2.image !== null && item2.image !== undefined && item2.image !== '') {
                item.smallImage = item2.image;
            }
        }
    }

    return (
        <section
            className="bg-home d-flex align-items-center"
            style={{ background: `url(${item.image}) center center`, backgroundSize:"cover" }}
            id="home"
        >
          <div className="bg-overlay bg-overlay-white"></div>
            <Container>
                <Row className="justify-content-center">
                    <Col md="9" className="mt-0 mt-md-5 pt-0 pt-md-5">
                        <div className="title-heading margin-top-100">
                            <h1 className="display-4 fw-bold mb-3">
                                {item.h1}
                            </h1>
                            <p className="para-desc mx-auto text-muted" dangerouslySetInnerHTML={{ __html: item.p }} />
                            <div className="mt-4 pt-2">
                                <a href={item.link} className={item.btnclass} >
                                    <i className={item.iClass} /> {item.btntext}
                                </a>
                            </div>
                        </div>
                        <div className="home-dashboard">
                            <img src={item.smallImage} alt="" className="img-fluid mover" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );

}

export default HeaderSingle;
