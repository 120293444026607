
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import StandClient from "client"
import Loader from "components/ui/Loader";

const LastOrder = () => {
    const [order, setOrder] = useState(null);
    const [payment, setPayment] = useState(null);
    const [paymentOnline, setPaymentOnline] = useState(false);

    useEffect(() => {
        StandClient.getLastOrder().then(result=>{
            let payment = null;
            if (result.order.paymentSet.length > 0) {
                payment = result.order.paymentSet[0];
            }
            setOrder(result.order);
            setPayment(payment);
            setPaymentOnline(result.order.paymentType === "ONLINE");
            }).catch(error=>{
                console.log(error);
            });
    }, []);

    const OnlinePaymentBlock = () => {
        let payment_status = "Ошибка статуса";
        let status = payment.status;
        if (status==="A_1") {
            payment_status = "Ожидает оплаты";
        }
        if (status==="A_2") {
            payment_status = "Ожидает оплаты";
        }
        if (status==="A_3") {
            payment_status = "Подтверждена";
        }
        if (status==="A_4") {
            payment_status = "Ошибка";
        }
        return 'Статус оплаты: '+payment_status;
    }

    if (order == null) {
        return (<Loader />);
    }

    document.title = 'Последний заказ - ' + StandClient.getConfigValueByName('site_title');

    return (
        <section className="bg-home d-flex align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8} md={12} className="text-center">
                        <div className="text-uppercase mt-4 display-4">Заказ №{order.id}</div>
                        <br />
                        <div className="text text-dark mb-4">
                            {(paymentOnline)?(
                                <OnlinePaymentBlock />
                            ):(null)}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="text-center">
                        {(paymentOnline && payment.status === "A_2")?(
                            <a className="btn btn-outline-primary mt-4" href={payment.url}>Повторить оплату</a>
                        ):(null)}{" "}
                        <Link to="/" className="btn btn-primary mt-4 ms-2">
                            На главную
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default LastOrder;
