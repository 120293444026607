import { Button } from '@gravity-ui/uikit';
import StandClient from "client"
import "./style.css"

const SelectCity = (props) => {
    const configure = StandClient.getFrontConfigureLocal()
    const jsonValues = JSON.parse(props.jsonValues);

    return (
        <div className='page_city'>
            {configure?.data.getFrontConfigure.logoIcon === '' || configure?.data.getFrontConfigure.logoIcon === 'null' ? (null) : (
                    <img src={configure?.data.getFrontConfigure.logoIcon} alt={"Logo"} width={64} height={64} style={{margin:8,marginRight:16}} />
            )}
            {configure?.data.getFrontConfigure.title}
            <div className='center_city'>
                <div className='center_city_content'>
                    <h1>Выберите Ваш город</h1>
                    {jsonValues.cities.map((value, key)=>{
                        return (
                            <div className='button_city' key={key}>
                                <a href={value.url}>
                                    <Button view="action" size="xl" >
                                        {value.name}
                                    </Button>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default SelectCity;
