import React from "react";
import { Container, Row, Col } from "reactstrap";
import Countdown from "react-countdown-now";

const HeaderTimer = (props) => {
    const items = (props.settings['sliders'] !== undefined && props.settings['sliders']['items'] !== undefined)?(props.settings['sliders']['items']):([]);
    const Completionist = () => <span>{props.settings.timer.completeText}</span>;
    const renderer = ({ hours, minutes, seconds, completed, days }) => {
        if (completed) {
            return <Completionist />;
        } else {
            return (
                <React.Fragment>
                    <div className="count-down">
                        <span className="count-number text-black">{days}</span>
                        <span className="count-head position-relative d-block text-black">Дней</span>
                    </div>
                    <div className="count-down">
                        <span className="count-number text-black">{hours}</span>
                        <span className="count-head position-relative d-block text-black">Часов</span>
                    </div>{" "}
                    <div className="count-down">
                        <span className="count-number text-black">{minutes}</span>
                        <span className="count-head position-relative d-block text-black">Минут</span>
                    </div>{" "}
                    <div className="count-down">
                        <span className="count-number text-black">{seconds}</span>
                        <span className="count-head position-relative d-block text-black">Секунд</span>
                    </div>
                </React.Fragment>
            );
        }
    };
    let item = items[0];
    let height = props.settings['height'];

    return (
        <section className="bg-half-260 w-100 d-table" 
            style={{ background: `url(${item.image}) center center`, backgroundSize:"cover", height:height, overflow:"hidden" }}
        >
            <div className="bg-overlay" style={{backgroundColor:'#edededaa'}} />
            <Container>
                <Row className="justify-content-center">
                    <Col lg="10" className="text-center">
                        <div className="title-heading">
                            <h4 className="text-success mb-3">
                                {item.h6}
                            </h4>
                            <h1 className="display-4 title-dark text-black fw-bold mb-3">
                                {item.h1}
                            </h1>
                            <p className="para-desc mx-auto text-black-50" dangerouslySetInnerHTML={{ __html: item.p }} />
                            <Row>
                                <Col md="12" className="text-center">
                                    <div id="eventdown">
                                        <Countdown date={props.settings.timer.end} renderer={renderer} />
                                    </div>
                                </Col>
                            </Row>
                            <div className="mt-4 pt-2">
                                <a href={item.link} className={item.btnclass} >
                                    <i className={item.iClass} /> {item.btntext}
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );

}

export default HeaderTimer;
