import React from "react";
import { Container, Row, Col } from "reactstrap";

const StaticPageContentBlock = (props) => {
    return (
        <section className="section">
            <Container>
                <Row className="align-items-center">
                    <Col lg={12} md={12} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                        { (props.page.content !== '') ? (React.createElement("div", { dangerouslySetInnerHTML: { __html: props.page.content } }) ) : null }
                        { (props.page.iframeUrl !== '') ? (<iframe title={props.page.slug} src={props.page.iframeUrl} width="100%" height="457" frameborder="0" />) : null }
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default StaticPageContentBlock;
